import React from 'react';
import NavBar from './NavBar';
import Sider from './../common/Sider';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_NAV_TAB } from 'src/reduxActions/actionNameEnums';
import { getSelectedNavKey } from './utils/utils';
import { useHistory } from 'react-router-dom';
import { getProjectName } from 'src/components/common/utils/utils';
import { createUseStyles } from 'react-jss';
import MobileNavBar from './MobileNavBar';
import { projectNames } from 'src/constants';

const useStyle = createUseStyles({
  canvas: {
    backgroundColor: '#F1F0F1',
    minHeight: '100vh',
  },
  canvasExcludingNavBar: {
    display: 'flex',
    paddingBottom: '10px',
  },
  routesComponent: {
    display: 'flex',
    flex: 7,
    overflow: 'auto',
    scrollbarWidth: 'none',
  },
});

const Layout = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { children } = props;
  const projectName = getProjectName();
  const analyticsTopBar = useSelector(
    (state: any) => state.config.analyticsTopBar,
  );
  const isDrawerOpen = useSelector((state: any) => state.config.leftPaneOpen);
  const isDrawerVisible = useSelector(
    (state: any) => state.config.leftPaneVisibility,
  );
  const organisationConfig = useSelector(
    (state: any) => state.masterData.organisationConfig,
  );
  const isTabletOrMobile = useSelector(
    (state: any) => state.config.isTabletOrMobile,
  );
  const { backgroundColour } = organisationConfig;
  const history = useHistory();
  const onChangeNavTab = (selectedNavTabs) => {
    dispatch({
      type: CHANGE_NAV_TAB,
      payload: selectedNavTabs,
    });
  };

  onChangeNavTab(getSelectedNavKey(history?.location.pathname));
  const isFullScreen = useSelector(
    (state: any) => state.dashboardGroupMetaData.isFullScreen,
  );
  const disabledNavBar = () => {
    return (
      projectName === projectNames.CUSTOMERLOGISTICS ||
      projectName === projectNames.TRANSPORTERPORTAL ||
      isFullScreen ||
      analyticsTopBar
    );
  };

  const showNavbar = () => {
    return (
      projectName !== projectNames.CUSTOMERLOGISTICS &&
      projectName !== projectNames.TRANSPORTERPORTAL &&
      !isTabletOrMobile &&
      !isFullScreen
    );
  };

  const showSider = () => {
    return (
      isDrawerVisible &&
      !isTabletOrMobile &&
      projectName !== projectNames.CUSTOMERLOGISTICS &&
      projectName !== projectNames.TRANSPORTERPORTAL
    );
  };

  return (
    <div className={classes.canvas}>
      {showNavbar() ? <NavBar isDrawerOpen={isDrawerOpen} /> : null}
      {isTabletOrMobile ? <MobileNavBar /> : null}
      <div
        className={classes.canvasExcludingNavBar}
        style={{
          backgroundColor: backgroundColour || '#F2EDE4',
        }}
      >
        {showSider() ? (
          <Sider isDrawerOpen={isDrawerOpen} />
        ) : null}
        <div
          className={classes.routesComponent}
          style={{
            width: isDrawerOpen ? undefined : '100%',
            height: !disabledNavBar() ? 'calc(100vh - 70px)' : '100vh',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
