import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Button } from 'antd';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import Icon from '@ant-design/icons';

import CustomDivider from './CustomDivider';
import { ReactComponent as ReportsSVG } from './../../assets/Reports.svg';
import { ReactComponent as DashboardSVG } from './../../assets/Dashboard.svg';
import { ReactComponent as ScheduledEmails } from './../../assets/ScheduledEmails.svg';
import { ReactComponent as MetricsSVG } from './../../assets/Metrics.svg';
import { ReactComponent as AlertsSVG } from './../../assets/Alerts.svg';
import { getPermissionType } from '../componentHelper';
import { permissionTypes } from 'src/constants';
import { useTranslation } from 'react-i18next';

const useStyle = createUseStyles({
  createNewButton: {
    width: '90%',
    height: '32px',
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    margin: '16px 0',
    cursor: 'pointer',
    alignItems: 'center',
    borderRadius: '0.2rem',
  },
  dropDownNavigationContainer: {
    width: '100%',
    padding: '15px 0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
});

const DropDownNaigation = (props: any) => {
  const { t } = useTranslation();
  const { onChangeNavTab } = props;
  const classes = useStyle();
  const history = useHistory();
  const currentSelectedNavTab = useSelector(
    (state: any) => state.config.currentSelectedNavTab,
  );
  const enableIncidentAlert = useSelector((state:any) => state?.masterData?.organisationConfig?.enableIncidentAlert);

  const handleRouteClick = (routeId, tabId = routeId) => {
    history.push(`/${routeId}`);
    onChangeNavTab([tabId]);
  };
  const userType = useSelector((state:any) => state?.masterData?.userConfig?.typeOfUser);
  let permissionType = 'user';
  if(userType !== undefined){
    permissionType = getPermissionType(userType);
  }
  return (
    <div className={classes.dropDownNavigationContainer}>
      <CustomDivider />
      <div style={{ width: '100%' }}>
        <Menu mode="inline" selectedKeys={currentSelectedNavTab}>
          <Menu.Item
            onClick={() => {
              history.push('/dashboard');
              onChangeNavTab(['dashboards', 'dashboards_all']);
            }}
            title={t('Dashboards')}
            key="dashboards"
            icon={
              <Icon component={(iProps) => (<DashboardSVG {...iProps} />)} />
            }
          >
            {t('Dashboards')}
          </Menu.Item>
          { permissionType  === permissionTypes.USER ? <></> :
          <Menu.Item
            onClick={() => {
              history.push('/reports');
              onChangeNavTab(['reports', 'reports_all']);
            }}
            title={t('Reports')}
            key="reports"
            icon={
              <Icon component={(iProps) => (<ReportsSVG {...iProps} />)} />
            }
          >
            {t('Reports')}
          </Menu.Item>
          }
          {permissionType !== permissionTypes.USER &&
            <Menu.Item
              onClick={() => {
                handleRouteClick('scheduled-emails');
              }}
              title={t('Scheduled Emails')}
              key="scheduled-emails"
              icon={
                <Icon component={(iProps) => (<ScheduledEmails {...iProps} />)} />
              }
            >
              {t('Scheduled Emails')}
            </Menu.Item>
          }
          {enableIncidentAlert && permissionType !== permissionTypes.USER ?
          <Menu.Item
              onClick={() => handleRouteClick('metrics')}
              key="metrics"
              title={t('Metrics')}
              icon={
                <Icon component={(iProps) => (<MetricsSVG {...iProps} />)} />
              }
            >
              {t('Metrics')}
            </Menu.Item>
            : null}
            {enableIncidentAlert && permissionType !== permissionTypes.USER ?
            <Menu.Item
              onClick={() => handleRouteClick('alerts')}
              key="alerts"
              icon={
                <Icon component={(iProps) => (<AlertsSVG {...iProps} />)} />
              }
            >
              {t('Alerts')}
            </Menu.Item> : null}
        </Menu>
      </div>
    </div>
  );
};

export default DropDownNaigation;
