import { DownloadOutlined, MoreOutlined, FileImageOutlined, FileExcelOutlined, InfoCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message, Popover, Tooltip } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getPermissionType } from 'src/components/componentHelper';
import { Switcher } from 'src/components/common/visualisations/Switcher/Switcher';
import { getParentWindowBaseURL, isManualQueryReport } from 'src/components/common/utils/utils';
import { isReportTypeHtmlEditior } from 'src/components/utils/utils';
import { SET_AUTO_REFRESH, SET_REFRESH_REPORTS_DATA} from 'src/reduxActions/actionNameEnums';
import { isMobile } from 'react-device-detect';
import { directDownloadChartData } from 'src/components/utils/utils';
import './chartContainerTopBar.css';
import { useTranslation } from 'react-i18next';

const useStyle = createUseStyles({
  base: {
    padding: '0.3rem 0.3rem 0rem 0.3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  title: {
    color: '#262626',
    fontWeight: '400',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  description: {
    color: '#525252',
    fontSize: '8px',
    fontWeight: 500,
    lineHeight: '0.75rem',
    paddingLeft: '0.625rem',
  },
  downloadIcon: {
    fontSize: '18px',
    marginRight: '16px',
    opacity: 0.2,
    '&:hover': {
      opacity: 1,
    },
  },
  moreOutlined: {
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  iconsDisplay: {
    display: 'inline-flex',
    cursor: 'pointer',
  },
  reportTitle: {
    lineHeight: '14px',
    fontSize: '14px',
    paddingLeft: '0.625rem',
    display: 'inline-block',
    color: 'black',
  },
  underline: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
  infoTooltip: {
    marginLeft: '0.5rem',
  },
  popOver: {
    backgroundColor: 'black',
  },
  popOverHeading: {
    color: 'grey',
    fontSize: '12px',
    cursor: 'auto',
    '&:hover': {
      textDecoration: 'none',
      color: 'grey',
      cursor: 'auto',
    },
  },
  popOverValue: {
    color: 'white',
    fontSize: '12px',
    cursor: 'auto',
    '&:hover': {
      textDecoration: 'none',
      color: 'white',
      cursor: 'auto',
    },
  },
});

const ChartContainerTopBar = (props) => {
    const { t } = useTranslation();
    const {
      reportName,
      reportDescription,
      reportDateRange,
      onClickDownloadChartData,
      onClickDownloadUnderlyingChartData,
      onDelete,
      reportId,
      chartRef,
      chartType,
      chartData,
      setFilterRemoveFromLocal,
      inEditMode,
      isChildReport = false,
      chartConfig,
      switcherFields,
      onSubmitSwitcher,
      dataMartType,
      reportType,
      clearAllTimeouts,
      fetching,
      lastRefreshTime,
    } = props;
    const classes = useStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const dashboardGroupType = useSelector(
      (state: any) => state.dashboardGroupMetaData.dashboardGroupType,
    );
    const isDefaultEditable = useSelector(
      (state: any) => state.masterData?.organisationConfig?.isDefaultEditable || false,
    );
    const isShipsyInternalUser = useSelector(
      (state: any) => state?.masterData?.userConfig?.isShipsyInternalUser,
    );
    const organisationConfig = useSelector((state: any) => state.masterData.organisationConfig);
    const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
    const isTabletOrMobile = useSelector((state: any) => state.config.isTabletOrMobile);
    const analyticsTopBar = useSelector((state: any) => state.config.analyticsTopBar);
    const { enableDataDownloads } = organisationConfig;
    const dateRangeVisible = chartConfig?.common?.dateRangeVisible?.data ?? true;
  const onMenuClicked: any = (item) => {
    switch (item.key) {
      case 'removeReport':
        setFilterRemoveFromLocal(true);
        onDelete();
        break;
      default:
        console.log(`${t('No action found for key')} ${item.key}`);
    }
  };
  const menu = (
    <Menu onClick={onMenuClicked}>
      <Menu.Item key={'removeReport'}>
        {t('Remove from Dashboard')}
      </Menu.Item>
    </Menu>
  );

  const userType = useSelector((state: any) => state?.masterData?.userConfig?.typeOfUser);
  let permissionType = 'user';
  if (userType !== undefined) {
    permissionType = getPermissionType(userType);
  }

  const downloadImage = () => {
    if (chartRef.current === undefined) {
      message.error({ content: t('No image to download.') });
    }
    const padding = 40;
    const imageData = chartRef.current?.toDataURL('image/png');
    const image = new Image();
    image.src = imageData;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = image.width + padding * 2;
      canvas.height = image.height + padding * 2;
      ctx ? (ctx.fillStyle = '#ffffff') : null;
      ctx?.fillRect(0, 0, canvas.width, canvas.height);
      ctx?.drawImage(image, padding, padding);
      const imageDataWithPadding = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imageDataWithPadding;
      link.download = `${reportName}_image.png`;
      link.click();
    };
  };

  const onClickDirectDownloadChartData = () => {
    directDownloadChartData(chartType, chartData);
  };

  const onClickDownload = (item) => {
    if (item.key === 'downloadChartImage') {
      downloadImage();
    }
    if (item.key === 'downloadChartData') {
      onClickDownloadChartData();
    }
    if (item.key === 'downloadUnderlyingChartData') {
      onClickDownloadUnderlyingChartData();
    }
    if (item.key === 'directlyDownloadChartData') {
      onClickDirectDownloadChartData();
    }
  };
  const downloadChartData = (
    <Menu.Item key="downloadChartData" onClick={onClickDownload}>
      <FileExcelOutlined /> {t('Download complete chart data')}
    </Menu.Item>
  );
  const downloadChartImage = (
    <Menu.Item key="downloadChartImage" onClick={onClickDownload}>
      <FileImageOutlined /> {t('Download chart image')}
    </Menu.Item>
  );
  const downloadUnderlyingChartData = (
    <Menu.Item key="downloadUnderlyingChartData" onClick={onClickDownload}>
      <FileExcelOutlined /> {t('Download underlying chart data')}
    </Menu.Item>
  );
  const directlyDownloadChartData = (
    <Menu.Item key="directlyDownloadChartData" onClick={onClickDownload}>
      <FileExcelOutlined /> {t('Direct Download Chart Data')}
    </Menu.Item>
  );
  const menuDownload = (
    <Menu style={{ cursor: 'pointer' }}>
      {!isTabletOrMobile && downloadChartData}
      {!isTabletOrMobile && downloadUnderlyingChartData}
      {chartType === 'bar' || chartType === 'line' || chartType === 'pie' ? (
        <>{downloadChartImage}</>
      ) : null}
      {directlyDownloadChartData}
    </Menu>
  );
  const visualizePath = `${getParentWindowBaseURL()}/analytics/visualize/${reportId}`;
  const handleClick = (e) => {
    e.preventDefault();
    if(isMobile) return;
    clearAllTimeouts();
    if(document.exitFullscreen) {
      dispatch({ type: SET_AUTO_REFRESH, payload: false });
      dispatch({ type: SET_REFRESH_REPORTS_DATA, payload: {}});
      document.exitFullscreen();
    }
    if (e.metaKey || e.ctrlKey) {
      window.open(visualizePath, '_blank');
    } else {
      message.loading({
        key: 'dashboardReportData',
        content: t('Fetching Report Meta Data'),
      });
      history.push(`/visualize/${reportId}`);
    }
  };
  const isReportAccessible = () => {
    if (isManualQueryReport(dataMartType)) {
      if (isShipsyInternalUser) {
        return true;
      }
      return false;
    }
    return permissionType !== 'user' && !analyticsTopBar ? true : false;
  };
  const InfoIcon: any = () => {
    if (lastRefreshTime || reportDescription) {
      const heading = t('Last Data Refresh: ');
       const handleContentClick = (e) => {
         e.stopPropagation(); // Stop the click event from propagating to the underlying elements
       };
      const content = (
        <div onClick={handleContentClick} className={classes.popOver}>
          {reportDescription && (
            <div>
              <a className={classes.popOverHeading}>{t('Description')}: </a>
              <a className={classes.popOverValue}>{t(reportDescription)}</a>
            </div>
          )}
          {lastRefreshTime && (
            <div>
              <a className={classes.popOverHeading}>{t(heading)}</a>
              <a className={classes.popOverValue}>{t(lastRefreshTime)}</a>
            </div>
          )}
        </div>
      );
      return (
        <Popover
          content={content}
          color="black"
          overlayClassName="popover-custom-overlay"
          placement="right"
        >
          <InfoCircleOutlined
            style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
          />
        </Popover>
      );
    }
    return;
  };
  return (
    <div className={classes.base}>
      <div className={classes.title}>
        <a
          href={isReportAccessible() ? visualizePath : undefined}
          onClick={isReportAccessible() ? handleClick : undefined}
        >
          <div className={classes.reportTitle}>
            {t(reportName)}{' '}
            {(lastRefreshTime || t(reportDescription)) && <InfoIcon />}
          </div>
        </a>
        <div className={classes.description}>
          {dateRangeVisible && reportDateRange && !fetching
            ? `(${t('Date Range')}: ${reportDateRange})`
            : null}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {chartType === 'pivotTable' &&
          chartConfig?.arrangement?.switchDimensions?.data && (
            <div className={classes.iconsDisplay}>
              <Switcher {...switcherFields} onSubmit={onSubmitSwitcher} />
            </div>
          )}
        {enableDataDownloads &&
        (isTabletOrMobile
          ? ['bar', 'pie', 'line'].includes(chartType)
          : true) ? (
          <div
            data-gtm-id="dashboard-report-download"
            className={classes.iconsDisplay}
          >
            {enableDummyDataSwitch ||
            isReportTypeHtmlEditior(reportType) ? null : (
              <Dropdown overlay={menuDownload} placement="bottomRight">
                <DownloadOutlined className={classes.downloadIcon} />
              </Dropdown>
            )}
            {(dashboardGroupType === 'user' || isDefaultEditable) &&
            inEditMode ? (
              <Dropdown overlay={menu}>
                <MoreOutlined className={classes.moreOutlined} />
              </Dropdown>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChartContainerTopBar;
