import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import NoVisualisationPlaceHolder from 'src/assets/EmptyState.png';

const useStyles = createUseStyles({
    imagePlaceHolder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height:'100%',
        flexDirection: 'column',
    },
    textStyle: {
        width: '28vw',
        fontSize: '20px',
        fontWeight: '600',
        color: '#262626',
        lineHeight: '28px',
        textAlign: 'center',
        margin: '2rem 0',
    },
});

const VisualisationTopBar = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return <>
           <div className={classes.imagePlaceHolder}>
                <img alt="" src={NoVisualisationPlaceHolder} />
                <span className={classes.textStyle}>
                    {t('It looks like there are no reports to show!')}
                    {t('Get started by generating a report')}
                </span>
            </div>
    </>;
};
export default VisualisationTopBar;
