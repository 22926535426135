import CustomSelect from '../CustomSelect';
import CustomRadio from '../customRadio';
import CustomSwitch from '../customSwitch';
import CustomDraggable from '../CustomDraggable';
import CustomNumberInput from '../CustomNumberInput';
import CustomColorPicker from '../CustomColorPicker';

const OptionMap = {
    singleSelect: CustomSelect,
    multiSelect : CustomSelect,
    toggle: CustomSwitch,
    radio: CustomRadio,
    draggableSection : CustomDraggable,
    numberInput: CustomNumberInput,
    colorPicker: CustomColorPicker,
};

export default OptionMap;
