import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { Colorpicker } from 'antd-colorpicker';

const useStyle = createUseStyles({
    basePane: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px',
        marginBottom: '20px',
    },
    prettyName: {
        lineHeight: '14px',
        letterSpacing: '0px',
        color: '#303030',
        height: '14px',
        fontSize: '12px',
        width: '65%',
    },
    colorPicker: {
        width: '35%',
    },
});

const CustomColorPicker = (props) => {
    const classes = useStyle();
    const { onChange, prettyName, value } = props;

    return (
        <div className={classes.basePane}>
            <span className={classes.prettyName}>{prettyName}</span>
            <Colorpicker
                popup
                value={value}
                onChange={onChange}
                className={classes.colorPicker}
            />
        </div>
    );
};

export default memo(CustomColorPicker);
