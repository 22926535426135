import { Divider, Button, Drawer, Select, AutoComplete, Popover, message, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import useSaveReport from '../hooks/saveReportHook';
import useDownloadReport from '../hooks/downloadReport';
import CustomRadio from 'src/components/common/customRadio';
import CustomInput from 'src/components/common/customInput';
import CustomSelectInput from 'src/components/common/CustomSelectInput';
import { useLazyQuery } from '@apollo/client';
import { GET_DEFAULT_DASHBOARD_CATEGORY } from '../../dashboard/hooks/dashboardQueries';
import { FileExcelOutlined, DownloadOutlined } from '@ant-design/icons';
import { Switcher } from 'src/components/common/visualisations/Switcher/Switcher';
import { GET_METRIC_TYPES } from 'src/api/routes';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { dbTypes } from 'src/constants';
import { isManualQueryReport } from 'src/components/common/utils/utils';
import { isReportTypeHtmlEditior } from 'src/components/utils/utils';
import { CHANGE_DOWNLOAD_MODAL_STATE } from 'src/reduxActions/actionNameEnums';
import { ReactComponent as DownloadIcon } from 'src/assets/Vector.svg';
import { directDownloadChartData } from 'src/components/utils/utils';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
  topBar: {
    width: '100%',
    padding: '0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: '16px',
    marginLeft: '1em',
    fontWeight: '500',
    lineHeight: '16px',
    color: '#262626',
  },
  divider: {
    margin: '0px',
  },
  basePane: {
    padding: '16px',
    margin: '16px 0px 0px 0px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  saveButton: {
    marginLeft: '1em',
  },
  drawer: {
    width: '460px',
  },
  message: {
    fontStyle: 'italic',
    height: '15px',
    color: '#262626',
    marginBottom: '16px',
  },
  drawerContainer: {
    // antv table pagination overlap issue
    zIndex: '1025',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  inputStyle: {
    width: '430px',
  },
  prettyName: {
    lineHeight: '14px',
    letterSpacing: '0px',
    color: '#303030',
    height: '14px',
    marginBottom: '0.5rem',
    fontSize: '12px',
  },
  popOverItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  popOverItemTitle: {
    padding: '4px',
  },
  popOverDisplay: {
    position: 'fixed',
    minHeight: '0.5px',
  },
  iconsDisplay: {
    display: 'inline-flex',
    cursor: 'pointer',
    margin: '0 1rem',
  },
  downloadLimitNote: {
    bottom: '0px',
    position: 'absolute',
  },
  downloadIcon: {
    borderRadius: '4px',
    fontSize: '12px',
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
const { Option } = Select;

const getDownloadLimitNote = (params) => {
  const {
    downloadRowLimitConfig,
    metricsObject,
    summarizationObject,
    enableRedshiftDump,
    selectedDbType,
    maxElasticSearchRowsToDownload,
    t,
  } = params;

  if (enableRedshiftDump && selectedDbType === dbTypes.REDSHIFT) {
    return;
  }

  if (!Array.isArray(downloadRowLimitConfig) || !downloadRowLimitConfig.length) {
    return;
  }

  const columnsLength = Object.keys({ ...metricsObject, ...summarizationObject }).length;

  for (const configObject of downloadRowLimitConfig) {
    const { minCols, maxCols, rows } = configObject;
    let requiredRows = rows;

    if ((minCols <= columnsLength && maxCols >= columnsLength) || (minCols <= columnsLength && !maxCols)) {
      if (selectedDbType === dbTypes.ELASTIC_SEARCH && rows > maxElasticSearchRowsToDownload) {
        requiredRows = maxElasticSearchRowsToDownload;
      }

      return `
        ${t('NOTE: As you have selected')} ${columnsLength} ${t('column')} ${columnsLength > 1 ? 's' : ''},
        ${t('Download limit on this report will be upto')} ${requiredRows} ${t('rows')}
      `;
    }
  }

  return '';
};

const VisualisationTopBar = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { type, switcherFields, onSubmitSwitcher, reportData } = props;
  const reportNameStoreValue = useSelector(
    (state: any) => state.reportMetaData.reportName,
  );
  const selectedDataMartType = useSelector((state: any) => state.reportMetaData.dataMartType);
  const reportcategory =
    useSelector((state: any) => state.reportMetaData?.reportCategory) || '';
  const reportDescriptionStoreValue = useSelector(
    (state: any) => state.reportMetaData.reportDescription,
  );
  const metricTypeStoreValue = useSelector(
    (state: any) => state.reportMetaData.metricType,
  );
  const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
  const chartConfigData = useSelector(
    (state: any) => state.chartsMetaData.chartConfig,
  );
  const { chartData, chartType } = useSelector(
    (state: any) => state.chartsMetaData?.chartData || {},
  );
  const downloadRowLimitConfig = useSelector((state:any) => state?.masterData?.organisationConfig?.downloadRowLimitConfig);
  const metricsObject = useSelector((state:any) => state.metrics) || {};
  const summarizationObject = useSelector((state: any) => state.summarization) || {};
  const enableRedshiftDump = useSelector((state:any) => state?.masterData?.organisationConfig?.enableRedshiftDump);
  const selectedReportType = useSelector((state: any) => state.reportMetaData.selectedReport);
  const selectedDbType = useSelector((state: any) => state.reportConfig?.reports[selectedReportType]?.dbType);
  const maxElasticSearchRowsToDownload = useSelector((state: any) => state.masterData.organisationConfig?.maxElasticSearchRowsToDownload);

  const [reportCategory, setReportCategory] = useState(reportcategory);
  const [metricTypeList, setMetricTypeList] = useState([]);
  const [categorylist, setCategoryList] = useState([]);
  const downloadReport = useDownloadReport();
  const reportUserTypeIfEditingReport = useSelector(
    (state: any) => state.config.userTypeOfEditReport,
  );
  const canEditDefaultReport = useSelector(
    (state: any) => state.masterData?.organisationConfig?.isDefaultEditable || false,
  );
  const reportID = useSelector((state: any) => state.reportMetaData.reportID);
  const downloadLimitNote = getDownloadLimitNote({
    downloadRowLimitConfig,
    metricsObject,
    summarizationObject,
    enableRedshiftDump,
    selectedDbType,
    maxElasticSearchRowsToDownload,
    t,
  });

  const classes = useStyles();
  const { isAlertDisableModalVisible, title = 'Report' } = props;
  const saveReport = useSaveReport(
    {
      reportName: reportNameStoreValue,
      reportDescription: reportDescriptionStoreValue,
      tab: props.tab,
      reportCategory: reportcategory,
      metricType: metricTypeStoreValue,
      automationSchedules: reportData?.automationSchedules,
    },
    props.options,
  );
  const handleDirectDataDownload = () => {
    directDownloadChartData(chartType, JSON.parse(chartData));
  };

  const downloadPopoverContent = (
    <div>
      <div
        className={classes.popOverItem}
        onClick={downloadReport.onClickDownload}
      >
        <FileExcelOutlined />
        <span className={classes.popOverItemTitle}>{t('Download complete chart data')}</span>
      </div>
      <div
        className={classes.popOverItem}
        onClick={() => downloadReport.onClickUnderlyingDataDownload([])}
      >
        <FileExcelOutlined />
        <span className={classes.popOverItemTitle}>
          {t('Download underlying data')}
        </span>
      </div>
      <div
        className={classes.popOverItem}
        onClick={handleDirectDataDownload}
      >
        <FileExcelOutlined />
        <span className={classes.popOverItemTitle}>{t('Direct Download Chart Data')}</span>
      </div>
    </div>
  );
  const footer = (
    <div style={{ textAlign: 'right' }}>
      <Button
        onClick={saveReport.toggleDrawerPosition}
        style={{ marginRight: 8 }}
      >
        {t('Cancel')}
      </Button>
      <Button
        loading={saveReport.savingInProgress}
        onClick={saveReport.onSubmit}
        type="primary"
      >
        {t('Submit')}
      </Button>
    </div>
  );
  const [getDefaultDashboardCategory] = useLazyQuery(
    GET_DEFAULT_DASHBOARD_CATEGORY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setCategoryList(data.getDefaultDashboardCategory);
      },
      onError: (data) => {},
    },
  );

  const [getMetricTypes] = useLazyAPI(GET_METRIC_TYPES, {
    type: 'GET',
    onCompleted: (data) => {
      setMetricTypeList(data);
    },
    onError: (data) => {
      message.error(t('Failed to load metric types'));
    },
  });

  useEffect(() => {
    if (saveReport.showSaveReportDrawer) {
      getDefaultDashboardCategory();
      if (title === 'Metric') {
        getMetricTypes();
      }
    }
  }, [saveReport.showSaveReportDrawer]);

  const onVisible = () => {
    dispatch({
        type: CHANGE_DOWNLOAD_MODAL_STATE,
        payload: true,
    });
  };
  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className={classes.topBar}>
          <span className={classes.cardTitle}>{t('Visualisation')}</span>
          <div style={{ display: 'flex' }}>
            {type === 'pivotTable' &&
              chartConfigData?.arrangement?.switchDimensions?.data && (
                <div className={classes.iconsDisplay}>
                  <Switcher
                    {...switcherFields}
                    style={{ borderRight: '1px solid #e6e6e6' }}
                    onSubmit={onSubmitSwitcher}
                  />
                </div>
              )}
            <Tooltip title={'Show Downloads'}>
              <Button
                data-gtm-id="top-nav-downloads"
                className={classes.downloadIcon}
                onClick={onVisible}
                icon={<DownloadIcon />}
              />
            </Tooltip>
            {!enableDummyDataSwitch && !isReportTypeHtmlEditior(selectedReportType)?
              <Tooltip title={'Download'}><Popover content={downloadPopoverContent} trigger="click">
                <Button style={{padding: '0px 8px'}} type="ghost">{<DownloadOutlined />}</Button>
              </Popover></Tooltip>: null}
            <Button
              disabled={isAlertDisableModalVisible}
              onClick={saveReport.toggleDrawerPosition}
              className={classes.saveButton}
              type="primary"
            >
              {t('Save')}
            </Button>
          </div>
        </div>
        <Drawer
          footerStyle={{ display: 'flex' }}
          bodyStyle={{ padding: '16px', position: 'relative' }}
          visible={saveReport.showSaveReportDrawer}
          width={'460px'}
          title={`Save ${title}`}
          footer={footer}
          className={classes.drawerContainer}
          onClose={saveReport.toggleDrawerPosition}
        >
          <div className={classes.message}>
            {t('You can check your saved')} {t(title)} in {t(title)} {t('tab')}
          </div>
          { (
              reportID &&
              (
                (canEditDefaultReport && reportUserTypeIfEditingReport === 'default') ||
                (reportUserTypeIfEditingReport === 'user')
              )
            ) ? (
            <CustomRadio
              prettyName={t('Save Preference')}
              value={saveReport.savePreference}
              onChange={(value) => {
                saveReport.setSavePreference(value);
              }}
              data={{
                options: [
                  {
                    value: 'saveExisting',
                    prettyName: (`${t('Save Over Existing')} ${t(title)}`),
                  },
                  { value: 'saveAsNew', prettyName: (`${t('Save as New')} ${t(title)}`) },
                ],
              }}
              direction={'horizontal'}
            />
          ) : null}
          <CustomInput
            onChange={saveReport.onChange.setReportName}
            prettyName={`${t(title)} ${t('Name')}`}
            value={saveReport.values.reportName}
          ></CustomInput>
          <CustomInput
            onChange={saveReport.onChange.setReportDescription}
            prettyName={`${t(title)} ${t('Description')}`}
            value={saveReport.values.reportDescription}
          ></CustomInput>
          {title === 'Metric' && (
            <CustomSelectInput
              onChange={saveReport.onChange.setMetricType}
              prettyName={`${t(title)} ${t('Type')}`}
              value={saveReport.values.metricType}
              showSearch={true}
              data={metricTypeList}
            ></CustomSelectInput>
          )}
          {canEditDefaultReport ? (
            <>
              <span className={classes.prettyName}>{`${t(title)} ${t('Category')}`}</span>
              <AutoComplete
                placeholder={t('Select Category')}
                value={saveReport.values.reportCategory}
                onChange={saveReport.onChange.setReportCategory}
                className={classes.inputStyle}
                onSelect={(value) => setReportCategory(value)}
              >
                {categorylist.map((option) => {
                  return <Option value={option}>{t(option)}</Option>;
                })}
              </AutoComplete>
            </>
          ) : null}
          <p className={classes.downloadLimitNote}>{downloadLimitNote}</p>
        </Drawer>
        <Divider className={classes.divider} />
      </div>
    </>
  );
};
export default VisualisationTopBar;
