import React from 'react';
import { Row, Col, DatePicker, Tooltip, Cascader, TimePicker, Input } from 'antd';
import { createUseStyles } from 'react-jss';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { dateHourMinFormat, frequencyTypes, hoursFormat, intervals } from 'src/constants';
import {
  generateIntervalOptionsV2,
  getUpdatedSchedulerData,
} from 'src/components/common/utils/schedulerAlertsUtils';
import { useTranslation } from 'react-i18next';

const { HOUR, MINUTE } = frequencyTypes;
const { CUSTOM } = intervals;

const useStyles = createUseStyles({
  sectionHeader: {
    display: 'flex',
    fontWeight: (styles: any) => styles.headerFontWeight || 600,
    letterSpacing: (styles: any) => styles.headerLetterSpacing || '0.04em',
    fontSize: (styles: any) => styles.headerFontSize || '1rem',
    marginBottom: '0.8rem',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: '0.2rem',
    alignItems: 'center',
  },
  formLabel: {
    fontWeight: (styles: any) => styles.labelFontWeight || 500,
    margin: '0.3rem 0',
  },
  customFrequency: {
    width: '100%',
    marginTop: '1em',
  },
});

const getInitialIntervalValue = (schedulerData) => {
  const { repeat = 1 } = schedulerData;
  const intervalValues = [schedulerData.selectedInterval];

  if (schedulerData.frequency === HOUR) {
    intervalValues.push(`${repeat}_hours`);
  } else if (schedulerData.frequency === MINUTE) {
    intervalValues.push(`${repeat}_minutes`);
  }

  return intervalValues;
};

const Scheduler = (props) => {
  const { t } = useTranslation();
  const { schedulerData, onSchedulerDataChange, schedulerFrequency, disabled = false, type = 'INCIDENT FREQUENCY', styles = {} } = props;
  const classes = useStyles(styles);
  const requiredIntervalValues = getInitialIntervalValue(schedulerData);

  const onChangeStartTimestamp = (updatedStartTimestamp) => {
    const updatedSchedulerData = getUpdatedSchedulerData({
      updatedStartTimestamp,
      schedulerData,
    });

    onSchedulerDataChange({ ...updatedSchedulerData });
  };

  const onChangeScheduleInterval = (values) => {
    const [intervalType, selectedSubOption] = values;
    const updatedSchedulerData = getUpdatedSchedulerData({
      intervalType,
      selectedSubOption,
      schedulerData,
    });

    onSchedulerDataChange({ ...updatedSchedulerData });
  };

  const onChangeTimeRange = (timeRange) => {
    const updatedSchedulerData = getUpdatedSchedulerData({
      timeRange,
      schedulerData,
    });

    onSchedulerDataChange({ ...updatedSchedulerData });
  };

  return (
    <div style={{ width: '100%' }}>
      <Row style={{ width: '100%' }}>
        <div className={classes.sectionHeader}>
          {t(type)}{' '}
          <Tooltip
            placement="right"
            title={
              t("E.g. define Weekly on Mondays at 9:00 am to track last week's attendance")
            }
            className={classes.tooltip}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      </Row>
      <Row style={{ width: '100%' }} gutter={[0, 20]}>
        <Col span={10}>
          <label className={classes.formLabel}>{t('Schedule')}</label>
          <DatePicker
            showTime
            showNow={false}
            style={{ width: '100%' }}
            value={schedulerData.startTimestamp}
            onChange={onChangeStartTimestamp}
            format={dateHourMinFormat}
            allowClear={false}
            disabled={disabled}
          />
        </Col>
        <Col span={10} offset={1}>
          <label className={classes.formLabel}>{t('Frequency')}</label>

          <Cascader
            options={generateIntervalOptionsV2(
              schedulerData.startTimestamp,
              schedulerFrequency,
            )}
            expandTrigger="hover"
            displayRender={(labels) => labels[labels.length - 1]}
            onChange={onChangeScheduleInterval}
            value={requiredIntervalValues}
            style={{ width: '100%' }}
            allowClear={false}
            disabled={disabled}
          />
        </Col>
      </Row>
      {requiredIntervalValues[0] === CUSTOM && (
        <Row className={classes.customFrequency} gutter={[0, 20]}>
          <Col span={10}>
            <label className={classes.formLabel}>{t('Custom Frequency')}</label>
            <Input
              value={schedulerData.customInterval}
              onChange={(e) => {
                onSchedulerDataChange({
                  ...schedulerData,
                  customInterval: e.target.value,
                });
              }}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
      )}
      {[HOUR, MINUTE].includes(schedulerData.frequency) && (
        <Row style={{ marginTop: '10px' }}>
          <Col span={7} style={{ width: '100%' }}>
            <label className={classes.formLabel}>{t('Hour Start and End Time')}</label>
            <TimePicker.RangePicker
              format={hoursFormat}
              value={
                schedulerData?.fromToRange?.timeRange?.length
                  ? [
                      moment(schedulerData.fromToRange.timeRange[0], 'HH'),
                      moment(schedulerData.fromToRange.timeRange[1], 'HH'),
                    ]
                  : null
              }
              onChange={onChangeTimeRange}
              style={{ width: '100%' }}
              disabled={disabled}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Scheduler;
