import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
    betweenContainer: {
      width: '50%',
      flexDirection: 'row',
    },
});

const BetweenNumberInput = (props) => {
    const { t } = useTranslation();
    const classes = useStyle();
    const { onChange, value, betweenContainer, size='small' } = props;
    const [firstInput, setFirstInput] = useState(value?.value?.from || '');
    const [secondInput, setSecondInput] = useState(value?.value?.to || '');
    useEffect(() => onChange({from:firstInput, to:secondInput}), [firstInput, secondInput]);

    return (
          <div className={betweenContainer ? betweenContainer : classes.betweenContainer}>
            <Input
              size={size}
              value={firstInput}
              onChange={(event) => setFirstInput(event.target.value)}
            />
            {!betweenContainer ? <span>{t('and')}</span> : null}
            <Input
              size={size}
              value={secondInput}
              onChange={(event) => setSecondInput(event.target.value)}
            />
          </div>
      );
};

export default BetweenNumberInput;
