/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, memo } from 'react';
import { Table, Button, Spin, Tooltip, List, Popover } from 'antd';
import { createUseStyles } from 'react-jss';
import config from '../../../settings';
import { CheckOutlined, DownloadOutlined, LoadingOutlined, ReloadOutlined, WarningOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { routeMetaData } from './../../../App';
import classNames from 'classnames';
import moment from 'moment';
import { usePageVisibility } from 'react-page-visibility';
import { useSelector } from 'react-redux';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { GET_DOWNLOADS } from 'src/api/routes';
import { dbTypes } from 'src/constants';
import { useTranslation } from 'react-i18next';
const useStyle = createUseStyles({
  mainDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    height: '52px',
  },
  loadingButton: {
    marginRight: '16px',
  },
  background: {
    backgroundColor: '#e2e8ed',
    padding: '8px',
  },
  title:{
    display: 'flex',
    borderRadius: '4px',
    height: '26px',
    width: '100%',
    backgroundColor: '#F7F7F7',
    marginBottom: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    gap: '.5em',
  },
  contentStyle: {
    color: '#111111',
    fontSize: '12px',
  },
  serialNumber: {
    width: '5%',
    margin: 'auto',
    textAlign: 'center',
  },
  report: {
    width: '25%',
    margin: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  maxRows: {
    width: '25%',
    textAlign: 'center',
    margin: 'auto',
  },
  createdAt: {
    width: '30%',
    margin: 'auto',
  },
  reportType: {
    width: '10%',
    margin: 'auto',
    textAlign: 'center',
  },
  status: {
    width: '10%',
    margin: 'auto',
    textAlign: 'center',
  },
  actions: {
    width: '10%',
    margin: 'auto',
  },
  requestDiv: {
    display: 'flex',
    height: '54px',
    width: '100%',
    border: '1px solid #FCFCFC',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    marginBottom: '2px',
    paddingLeft: '16px',
    paddingRight: '16px',
    gap: '1em',
  },
});
interface statusPageProps {
  activePage: String;
}

const StatusPage = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [listOfRequests, setlistOfRequests] = useState([]);
  const [intervalId, setIntervalId] = useState(0);
  const isVisible = usePageVisibility();
  const downloadModalVisibility = useSelector((state:any) => state.config.downloadModalVisibility);
  const downloadRowLimitConfig = useSelector((state:any) => state?.masterData?.organisationConfig?.downloadRowLimitConfig);
  const enableRedshiftDump = useSelector((state:any) => state?.masterData?.organisationConfig?.enableRedshiftDump);
  const allReportsConfig = useSelector((state: any) => state.reportConfig?.reports);
  const maxElasticSearchRowsToDownload = useSelector((state: any) => state.masterData.organisationConfig?.maxElasticSearchRowsToDownload);
  const showMaxRowsColumn = Array.isArray(downloadRowLimitConfig) && downloadRowLimitConfig.length;

  const [fetchRequests, {loading }] = useLazyAPI(GET_DOWNLOADS, {
    type: 'GET',
    onCompleted: (data) => {
      setlistOfRequests(data);
    },
  });

  useEffect(() => {
    fetchRequests();
    if(isVisible && downloadModalVisibility){
      clearInterval(intervalId);
      setIntervalId(window.setInterval(fetchRequests, config.DOWNLOAD_PAGE_POLL_INTERVAL));
    }else{
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  },[isVisible, downloadModalVisibility]);

  const getMaxDownloadRows = (params) => {
    const { dbType, columnsLength } = params;

    if (!showMaxRowsColumn  || (enableRedshiftDump && dbType === dbTypes.REDSHIFT)) {
      return '-';
    }

    for (const configObject of downloadRowLimitConfig) {
      const { minCols, maxCols, rows } = configObject;

      if ((minCols <= columnsLength && maxCols >= columnsLength) || (minCols <= columnsLength && !maxCols)) {
        if (dbType === dbTypes.ELASTIC_SEARCH && rows > maxElasticSearchRowsToDownload) {
          return maxElasticSearchRowsToDownload;
        }

        return rows;
      }
    }

    return '-';
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div className={classes.mainDiv}>
        <div className={classes.loadingButton} onClick={() => fetchRequests()}>
          {loading ? <LoadingOutlined style={{fontSize: '14px'}}/> : <ReloadOutlined />}
        </div>
      </div>
      <div className={classes.background}>
        <div className={classes.title}>
            <div className={classes.serialNumber}>{t('S.No')}</div>
            <div className={classes.report}>{t('Report')}</div>
            <div className={classes.createdAt}>{t('Created At')}</div>
            <div className={classes.status}>{t('Filter')}</div>
            <div className={classes.status}>{t('Columns')}</div>
            { showMaxRowsColumn && <div className={classes.maxRows}>{t('Max Rows')}</div> }
            <div className={classes.status}>{t('Status')}</div>
            <div className={classes.actions}>{t('Actions')}</div>
        </div>
        {listOfRequests?.map((request, index) => {
          const {
            reportTypePrettyName,
            creationTime,
            status,
            S3Url,
            filters,
            metricesPrettyName,
            name,
            customAggregateMetricesPrettyName,
            reportType,
          } = request;
          const allColumns = [...metricesPrettyName, ...customAggregateMetricesPrettyName];
          const dbType = allReportsConfig[reportType]?.dbType;
          const maxRows = getMaxDownloadRows({ dbType, columnsLength: allColumns.length });

          const filterContent = <List style={{zIndex: 2}} bordered dataSource={filters}
                                    renderItem={(item:any) => (
                                        <List.Item>
                                          {item.filter.prettyName}
                                        </List.Item>)}/>;

          const metricesContent = <List style={{zIndex: 2}} bordered dataSource={allColumns}
                            renderItem={(item:string) => (
                              <List.Item>
                                {item}
                              </List.Item>)}/>;

          return (
            <div className={classes.requestDiv}>
              <div
                className={classNames(
                  classes.serialNumber,
                  classes.contentStyle,
                )}
              >
                {index + 1}
              </div>
              <Tooltip
                title={
                  name !== null && name !== '' ? name : reportTypePrettyName
                }
              >
                <div
                  className={classNames(classes.report, classes.contentStyle)}
                >
                  {name !== null && name !== '' ? name : reportTypePrettyName}
                </div>
              </Tooltip>
              <div
                className={classNames(classes.createdAt, classes.contentStyle)}
              >
                {moment(creationTime).format('D MMM YYYY, hh:mm A')}
              </div>
              <div
                className={classNames(classes.reportType, classes.contentStyle)}
              >
                <Popover content={filterContent} title="Filters">
                  <span style={{ cursor: 'pointer' }}>
                    <a>{t('Show Filter')}</a>
                  </span>
                </Popover>
              </div>
              <div
                className={classNames(classes.reportType, classes.contentStyle)}
              >
                <Popover content={metricesContent} title="Metrices">
                  <span style={{ cursor: 'pointer' }}>
                    <a>{t('Show Columns')}</a>
                  </span>
                </Popover>
              </div>
              {showMaxRowsColumn && (
                <div
                  className={classNames(classes.maxRows, classes.contentStyle)}
                >
                  {maxRows}
                </div>
              )}
              <div className={classNames(classes.status, classes.contentStyle)}>
                {status === 'RECIEVED' ? (
                  <Tooltip title={t(status)}>
                    <LoadingOutlined className={'iconLoading'} />
                  </Tooltip>
                ) : status === 'COMPLETE' ? (
                  <Tooltip title={t(status)}>
                    <CheckOutlined className={'iconChecked'} />
                  </Tooltip>
                ) : (
                  <Tooltip title={t(status)}>
                    <WarningOutlined className={'iconWarning'} />
                  </Tooltip>
                )}
              </div>
              <div className={classes.actions}>
                {
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {status === 'COMPLETE' ? (
                      <a href={S3Url} aria-label="Download Data File">
                        <Tooltip title={t('Download Data')}>
                          <DownloadOutlined />
                        </Tooltip>
                      </a>
                    ) : null}
                  </div>
                }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(StatusPage);
