import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import GeneralFilter from 'src/components/common/GeneralFilter';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import PopOverPane from './popOverPane';
import SuggestedFilter from './suggestedFilter';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
    switchStyle:{
        marginRight: '10px',
    },
    switchContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    aggregate: {
        width: '50px',
    },
    rightPane:{
        display: 'flex',
        alignItems: 'center',
        marginRight: '15px',
    },
});

const useStyleOptions = createUseStyles({
    option: {
        '&:hover': {
            backgroundColor: '#E7F7FE',
        },
    },
});

const ListOptions = (props) => {
    const { t } = useTranslation();
    const classes = useStyleOptions();
    const { options, onChange } = props;
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        {options.map(item => <div onClick={() => onChange(item.name)}
            style={{ display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '150px',
                height: '30px',
                cursor: 'pointer',
            }}
            className={classes.option}>
            {t(item.prettyName)}
        </div>)}
    </div>;
};

const FiltersSelection = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { filters, description, filterData, onChangeRadio, onChangeFilterData, isFilterListMutable = true, showfreezeOption = true } = props;
    const { enableCustomColumnDrawer,initialSuggestedFilters=[], isEditing, onSelectFilter } = props;
    const { filtersOptions, onSelectFilterMetrics, selectedData, onRemoveFilter } = filters;
    const filteredSelectedFilters = selectedData.filter((filter) => filter.section == null || filter.filterMetaData?.required);
    const selectedReport = useSelector((state:any) => state.reportMetaData.selectedReport);
    const allCharts = useSelector((state: any) => state.reportConfig.reports);
    const dbType = allCharts[selectedReport]?.dbType;
    const metrics = useSelector((state:any) => state.reportConfig.reports?.[selectedReport]?.metrics) || [];
    const { title } = props;
    const filtersMetaData = useSelector((state:any) => state?.masterData?.supportedFilters);

    const handleSelectFilter = (a, b) => {
        onSelectFilterMetrics(a, b);
        onSelectFilter && onSelectFilter(a, b);
    };

    return <SectionPartContainer
                enableCustomColumnDrawer={enableCustomColumnDrawer}
                showTitle={isFilterListMutable}
                title={ t(title) || t('Filters') }
                description={t(description)}
                onChangeSelect={handleSelectFilter}
                options={filtersOptions}
                initialValue={filteredSelectedFilters.map(element => element.value)}
                onTitleChanges={console.log}
                showCount
                >
                {filteredSelectedFilters
                    .map(row => {
                    let filterListRadio = filtersMetaData[row.type] || [];
                    if(dbType === 'elasticSearch' && row.type === 'timestamp'){
                        filterListRadio = filterListRadio.filter(
                            item => item.name !== 'timestampzCurrent' && item.name !== 'timestampzPrevious',
                        );
                    }
                    const defaultSelection = filterListRadio[0];
                    const filterRadioChangeHandler = onChangeRadio(row.value);
                    const currentMetric = metrics.find((metric) => metric.value === row.value);
                    const searchOptions = currentMetric?.searchOptions;
                    const filterProps = {
                        type: row.type,
                        searchOptions,
                        metricsName: row.value,
                        filterMetaData:row.filterMetaData,
                        filterListRadio,
                        customDivider:true,
                        onChangeRadio: filterRadioChangeHandler,
                        onChangeFilterData: onChangeFilterData(row.value),
                        filterData,
                        showfreezeOption,
                        selectedFilterReportType: row.reportType,
                        showAdditionalFilters: true,
                        prettyName:row.prettyName,
                        isEditing,
                    };
                    const selectedRadio = filterData[row.value].selectedFilter;
                    const selectedRadioMetaData = filterListRadio.find((filter) => filter.name === selectedRadio);
                    const isRequired = row.filterMetaData?.required || false;
                    const selectedValue = filterData?.[row.value]?.selectedFilter;
                    return (
                        <>
                          <RowContainer
                            onRemove={() => {
                              onRemoveFilter(row.value);
                            }}
                            key={`${row.value}-${JSON.stringify(row.data)}`}
                            isTitleRequired={isRequired}
                            title={t(row.prettyName)}
                            description={t(row.description)}
                            showLeftPane={true}
                            showLeftPaneFilterComponent={true}
                            aggregate={row.aggregatePrettyName}
                            isFilterListMutable={isFilterListMutable}
                            LeftPaneComponent={PopOverPane}
                            filterData = {row.filterData}
                            metricsName = {row.value}
                            customDivider = {false}
                            LeftPaneFilterComponent={SuggestedFilter}
                            initialSuggestedFilters={initialSuggestedFilters}
                            rowType = {row.type}
                            leftPaneProps = {{
                              classes,
                              value: selectedRadioMetaData || defaultSelection,
                              Content: <ListOptions
                                  options={isRequired ?
                                    filterListRadio.filter((filterElement) => filterElement.name !=='doNotApplyThisFilter'):
                                    filterListRadio}
                                  onChange={(k) => {
                                    filterRadioChangeHandler(k);
                                  }}
                                  value={selectedRadioMetaData || defaultSelection}
                               {...filterProps}/>,
                            }}
                            // leftPaneProps = {{
                            //     value: selectedRadioMetaData || defaultSelection,
                            //     Content: <GeneralFilter {...filterProps}/> }}
                          >
                            {/* <PopOverPane
                                classes={classes}
                                value={selectedRadioMetaData || defaultSelection}
                                Content={<GeneralFilter {...filterProps}/>}/> */}
                          </RowContainer>
                          <GeneralFilter {...filterProps} />
                        </>
                      );
                })}
                <div className={classes.switchContainer}></div>
            </SectionPartContainer>;
    };
export default memo(FiltersSelection);
