import Truck from 'src/assets/Truck.svg';
import Hub from 'src/assets/Hub.svg';
import Warehouse from 'src/assets/Warehouse.svg';
import Delivery from 'src/assets/Delivery.svg';
import Pickup from 'src/assets/Pickup.svg';
import Rider from 'src/assets/Rider.svg';
import Pin from 'src/assets/Pin.svg';
import client from 'src/apolloClient';
import { GET_REPORT_VISUALISATION } from 'src/components/pages/dashboard/hooks/dashboardQueries';
import { getParentWindowBaseURL, isValidJSON } from '../utils/utils';
import { MapDataItem } from './ChartTypes';
import { PointLayer, Popup, PolygonLayer } from '@antv/l7';
import lodashValues from 'lodash/values';
import {
  getfilterWithUnSelectedValues,
  transformFilters,
  transformFilterWithSelectedValue,
} from '../utils/filter';
import chroma from 'chroma-js';

export const calculateTableColumnWidth = (props) => {
  const { metrics, dashboardColumnWidth, columns, containerWidth } = props;
  const columnWidth = {};
  for (const key in metrics) {
    if (metrics[key]) {
      const { aliasName } = metrics[key];
      if (metrics[key]?.columnWidth) {
        columnWidth[aliasName] = metrics[key]?.columnWidth;
      }
    }
  }
  const widthByFieldValue = dashboardColumnWidth
    ? dashboardColumnWidth
    : !columnWidth || Object.keys(columnWidth).length === 0
    ? columns.reduce((acc, column) => {
        const size = Math.floor(containerWidth / columns.length) + 1;
        acc[column] = Math.max(size, 100);
        return acc;
      }, {})
    : columnWidth;
  return widthByFieldValue;
};

export const calculatePivotColumnWidth = (props) => {
  const {
    metrics,
    dashboardColumnWidth,
    columns,
    containerWidth,
    distinctColCount,
  } = props;
  const columnWidth = {};
  for (const key in metrics) {
    if (metrics[key]) {
      const { aliasName } = metrics[key];
      if (metrics[key]?.columnWidth && columns.includes(aliasName)) {
        columnWidth[aliasName] = metrics[key]?.columnWidth;
      }
    }
  }
  const columnsCount = distinctColCount || columns.length;
  const widthByFieldValue = dashboardColumnWidth
    ? dashboardColumnWidth
    : !columnWidth || Object.keys(columnWidth).length === 0
    ? columns.reduce((acc, column) => {
        const size = Math.floor(containerWidth / columnsCount) + 1;
        acc[column] = Math.max(size, 100);
        return acc;
      }, {})
    : columnWidth;
  return widthByFieldValue;
};

export const mapMarkerConfig = {
  truck: {
    image: Truck,
    size: 39,
  },
  hub: {
    image: Hub,
    size: 20,
  },
  warehouse: {
    image: Warehouse,
    size: 20,
  },
  delivery: {
    image: Delivery,
    size: 20,
  },
  pickup: {
    image: Pickup,
    size: 20,
  },
  rider: {
    image: Rider,
    size: 20,
  },
  pin: {
    image: Pin,
    size: 25,
  },
};

export const generateCircleCoordinatesForMap = (
  lng: number,
  lat: number,
  radius: number,
): [number, number][] => {
  const numPoints = 100; // Number of points required to draw a circle
  const coordinates: [number, number][] = [];
  const angleStep = (2 * Math.PI) / numPoints;
  const cosLat = Math.cos(lat * (Math.PI / 180));

  for (let i = 0; i <= numPoints; i++) {
    const angle = i * angleStep;
    const dx = (radius / (111.32 * cosLat)) * Math.cos(angle);
    const dy = (radius / 111.32) * Math.sin(angle);
    coordinates.push([lng + dx, lat + dy]);
  }

  return coordinates;
};

export const fetchReportData = async (
  reportId: string,
  globalFilters: any,
  enableDummyDataSwitch: boolean,
) => {
  try {
    const { data } = await client.query({
      query: GET_REPORT_VISUALISATION,
      variables: {
        globalFilters,
        reportId,
        dashboardId: undefined,
        isDummyDataRequest: enableDummyDataSwitch,
      },
      fetchPolicy: 'no-cache',
    });

    if (data) {
      const chartData = JSON.parse(data.getReportVisualisation.chartData);
      const visualizeMetaData = JSON.parse(
        data.getReportVisualisation.visualizeMetaData,
      );
      let radius = 0;

      if (
        Array.isArray(data.getReportVisualisation.chartOptions) &&
        data.getReportVisualisation.chartOptions.length
      ) {
        const radiusOption = data.getReportVisualisation.chartOptions.find(
          (option) => option.option === 'radius',
        );
        radius = radiusOption ? JSON.parse(radiusOption.data).value : 0;
      }

      const updatedData = chartData?.map((data: any) => ({
        ...data,
        ...(radius > 0 && { radius }),
      }));

      return {
        chartData: updatedData,
        chartOptions: data.getReportVisualisation.chartOptions,
        visualizeMetaData,
      };
    }
  } catch (error) {}
  return { chartData: [], visualizeMetaData: {} };
};

export const createMapData = (
  mainReportData: MapDataItem[],
  linkedReportsData: any,
  mainReportPolygonProperties: any,
): MapDataItem[] => {
  return [
    ...mainReportData.map((data) => ({
      ...data,
      ...(mainReportPolygonProperties?.radius > 0 && {
        ...mainReportPolygonProperties,
      }),
    })),
    ...(Object.values(linkedReportsData)
      .map((data: any) => data.chartData)
      .reduce((acc, val) => acc.concat(val), []) || []),
  ].filter((data) => (data.x || data.x === 0) && (data.y || data.y === 0));
};

export const setupScene = (
  scene: any,
  mapData: MapDataItem[],
  pointLayerRef,
) => {
  // Remove existing point layer if any
  if (pointLayerRef.current) {
    scene.removeLayer(pointLayerRef.current);
  }

  pointLayerRef.current = null;

  if (!mapData.length) return;

  const pointLayer = new PointLayer({})
    .source(mapData, {
      parser: {
        type: 'json',
        x: 'y', // longitude
        y: 'x', // latitude
      },
    })
    .shape('marker')
    .size('marker', (marker) => mapMarkerConfig[marker]?.size || 20);

  scene.addLayer(pointLayer);
  pointLayerRef.current = pointLayer;
};

const addPolygonLayer = (
  scene: any,
  mapData: MapDataItem[],
  polygonLayerRef,
) => {
  const polygonArray = mapData.filter((data) => data.radius);
  const polygonLayers: any = [];
  const densities = polygonArray
    .map((data) => data.density)
    .filter((density): density is number => density !== undefined);
  const minDensity = densities.length > 0 ? Math.min(...densities) : 0;
  const maxDensity = densities.length > 0 ? Math.max(...densities) : 1;
  if (polygonArray.length) {
    polygonArray.forEach((data) => {
      if (data.radius) {
        const circleCoordinates = generateCircleCoordinatesForMap(
          data.y,
          data.x,
          data.radius,
        );

        const startColor = data.startColor?.rgb;
        const startColorRGBA = startColor
          ? `rgba(${startColor.r}, ${startColor.g}, ${startColor.b}, ${startColor.a})`
          : null;
        const endColor = data.endColor?.rgb;
        const endColorRGBA = endColor
          ? `rgba(${endColor.r}, ${endColor.g}, ${endColor.b}, ${endColor.a})`
          : null;

        const shouldShowGradient =
          data.density && data.startColor && data.endColor;

        if (shouldShowGradient) {
          const gradient = chroma
            .scale([startColorRGBA, endColorRGBA])
            .mode('lch')
            .colors(10)
            .map((color) => chroma(color).rgba().join(','));

          const gradientData = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {
                  ...(data.densityLabel && { name: data.densityLabel }),
                  density: data.density ?? 0,
                },
                geometry: {
                  type: 'Polygon',
                  coordinates: [circleCoordinates],
                },
              },
            ],
          };
          const polygonLayer = new PolygonLayer({})
            .source(gradientData)
            .scale('density', {
              type: 'quantile',
              domain: [minDensity, maxDensity],
            })
            .color(
              'density',
              gradient.map((item) => `rgba(${item})`),
            )
            .shape('fill')
            .active(false)
            .style({
              opacityLinear: {
                enable: true,
                dir: 'in',
              },
            });

          const textLayer = new PolygonLayer({})
            .source(gradientData)
            .shape('name', 'text')
            .size(12)
            .color('rgba(0, 0, 0, 0.4)')
            .style({
              textAnchor: 'center',
              textOffset: [0, 0],
              strokeOpacity: 1.0,
              fill: '#000',
            });

          scene.addLayer(polygonLayer);
          scene.addLayer(textLayer);
          polygonLayers.push(polygonLayer);
          polygonLayers.push(textLayer);
        } else {
          const polygonLayer = new PolygonLayer({})
            .source({
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: [circleCoordinates],
                  },
                },
              ],
            })
            .shape('fill')
            .color(startColorRGBA || 'rgba(255,0,0,0.1)');

          scene.addLayer(polygonLayer);
          polygonLayers.push(polygonLayer);
        }
      }
    });

    polygonLayerRef.current = polygonLayers;
  }
};

export const setupPolygonLayers = (
  scene: any,
  mainReportData: MapDataItem[],
  linkedReportsData,
  polygonLayerRef,
) => {
  // Remove existing polygon layers if any
  if (polygonLayerRef.current?.length) {
    polygonLayerRef.current.forEach((layer) => {
      scene.removeLayer(layer);
    });
  }
  polygonLayerRef.current = null;
  addPolygonLayer(scene, mainReportData, polygonLayerRef);

  Object.values(linkedReportsData || {})?.forEach((data: any) => {
    const startColor = JSON.parse(
      data?.chartOptions?.find((option) => option.option === 'startColor')
        ?.data || '{}',
    )?.value || '';
    const endColor = JSON.parse(
      data?.chartOptions?.find((option) => option.option === 'endColor')?.data ||
        '{}',
    )?.value || '';

    const mapData = data?.chartData.map((item) => ({
      ...item,
      startColor,
      endColor,
    }));

    addPolygonLayer(scene, mapData, polygonLayerRef);
  });
};

export const setupPopups = (scene: any, pointLayer: any, allData: any) => {
  let popup: Popup | null = null;

  const showPopup = (e: any) => {
    const { lng, lat } = e.lngLat;
    const featureData = e.feature;

    // to hide keys added by L7 + keys used for plotting
    const unwantedKeys = ['_id', 'coordinates', 'marker', 'x', 'y'];
    const popupContent = Object.keys(featureData)
      .filter((key) => !unwantedKeys.includes(key))
      .map((key) => {
        const item = allData[key];
        const value = featureData[key];
        if (item && item.urlPath) {
          const link = `${getParentWindowBaseURL()}${item.urlPath?.replace(
            '{id}',
            value,
          )}`;
          return `<strong>${item.prettyName}:</strong> <a href=${link} target="_blank">${value}</a>`;
        } else if (item) {
          return `<strong>${item.prettyName}:</strong> ${value}`;
        }
        return '';
      })
      .filter((item) => item)
      .join('<br>');

    // Remove existing popup if any
    if (popup) {
      popup.remove();
    }

    popup = new Popup({ closeOnClick: true, closeOnEsc: true });
    popup.setLnglat({ lng, lat }).setHTML(popupContent);
    scene.addPopup(popup);
  };

  if (pointLayer) {
    pointLayer.on('mouseenter', showPopup);
    pointLayer.on('click', showPopup);

    // Clear the popup when the mouse leaves the marker
    pointLayer.on('mouseleave', () => {
      if (popup) {
        popup.remove();
        popup = null; // Reset the popup reference
      }
    });
    pointLayer.on('mouseout', () => {
      setTimeout(() => {
        if (popup) {
          popup.remove();
          popup = null; // Reset the popup reference
        }
      }, 1000);
    });
  }
};

export function extractData(visualizeMetaData: any, linkedReportsData: any) {
  const columnsData: { [key: string]: any } =
    visualizeMetaData.columnsData || {};
  const visualizeSummarizeData: { [key: string]: any }[] =
    visualizeMetaData.summarizeData || [];

  const visualizeSummarizeLookup: { [key: string]: any } =
    visualizeSummarizeData.reduce((acc: { [key: string]: any }, item: any) => {
      if (item && item.name) {
        acc[item.name] = item;
      }
      return acc;
    }, {});

  const linkedColumnsData: { [key: string]: any } = Object.values(
    linkedReportsData,
  ).reduce((acc: { [key: string]: any }, data: any) => {
    if (data && data.visualizeMetaData) {
      if (data.visualizeMetaData.columnsData) {
        const columns = data.visualizeMetaData.columnsData as {
          [key: string]: any;
        };
        acc = { ...acc, ...columns };
      }
      if (data.visualizeMetaData.summarizeData) {
        const summarizeData = data.visualizeMetaData.summarizeData as {
          [key: string]: any;
        }[];
        summarizeData.forEach((item) => {
          if (item && item.name) {
            acc[item.name] = item;
          }
        });
      }
    }
    return acc;
  }, {});

  return { ...columnsData, ...visualizeSummarizeLookup, ...linkedColumnsData };
}

export const getGlobalFilters = (
  dashboardGroupMetaData,
  dashboardFilter,
  supportedFilters,
) => {
  let globalFilters: any[] = [];
  if (dashboardGroupMetaData?.dashboardGroupId) {
    const filters = lodashValues(
      dashboardFilter[dashboardGroupMetaData.currentDashboardGroupTab],
    );
    const filtersWithUnselectedValues = getfilterWithUnSelectedValues(
      filters,
      supportedFilters,
    ) as Array<any>;
    const filterWithSelectedValue = filters.filter(
      (filter) => !filtersWithUnselectedValues.includes(filter.metric),
    );
    const refinedFilterWithSelectedValue = filterWithSelectedValue.filter(
      transformFilterWithSelectedValue,
    );
    globalFilters = transformFilters(refinedFilterWithSelectedValue);
  }
  return globalFilters;
};

export const getFormattedFieldValue = (fieldValue: any, configData: any, fieldType: any): any => {
  const commaSeparatorType = configData?.graphic?.commaSeparator?.data;
  const locale =
    commaSeparatorType === 'international'
      ? 'en-US'
      : commaSeparatorType === 'indian'
      ? 'en-IN'
      : null;

  if (typeof fieldValue === 'number') {
    return {
      formattedValue: locale ? fieldValue.toLocaleString(locale) : fieldValue,
      value: fieldValue,
    };
  }

  const giveJSONModalSupport = fieldValue && isValidJSON(fieldValue) && Object.keys(JSON.parse(fieldValue)).length > 0;
  if (fieldType === 'json' && giveJSONModalSupport) {
    return {
      formattedValue: 'Click here to view JSON',
      value: 'Click here to view JSON',
    };
  }

  return {
    formattedValue: fieldValue,
    value: fieldValue,
  };
};

export const getBoundsFromPoints = (points: any = []) => {
  if (points.length === 0) return [[], []];

  const minLat = Math.min(...points.map((p) => p?.x));
  const maxLat = Math.max(...points.map((p) => p?.x));
  const minLng = Math.min(...points.map((p) => p?.y));
  const maxLng = Math.max(...points.map((p) => p?.y));

  const latPadding = (maxLat - minLat) * 0.05;
  const lngPadding = (maxLng - minLng) * 0.05;

  return [
    [minLng - lngPadding, minLat - latPadding],
    [maxLng + lngPadding, maxLat + latPadding],
  ];
};
