import { Button, Popover, Switch } from 'antd';
import React, { useEffect, useState, memo } from 'react';
import { createUseStyles } from 'react-jss';
import ColumnsSection from './dataSetupContainerPart/columns';
import SummariseBy from './dataSetupContainerPart/summariseBy';
import FiltersSection from './dataSetupContainerPart/filters';
import HavingSection from './dataSetupContainerPart/having';
import SortBySection from './dataSetupContainerPart/sortBy';
import { useDispatch, useSelector } from 'react-redux';
import {
    CHANGE_HAVING_AGGREGATE,
    FILTER_RADIO_SELECTED,
    FILTER_SELECTED_VALUE_CHANGED,
    HAVING_FILTER_RADIO_SELECTED,
    HAVING_FILTER_SELECTED_VALUE_CHANGED,
    REMOVE_CUSTOM_COLUMNS_GLOBAL,
    REMOVE_CUSTOM_COLUMNS_LOCAL,
    SET_CUSTOM_COLUMNS_GLOBAL,
    SET_CUSTOM_COLUMNS_LOCAL,
    DUMMY_DATA_SWITCH} from 'src/reduxActions/actionNameEnums';
import CustomColumnDrawer from './dataSetupContainerPart/customColumnsDrawer';
import DataDumpDrawer from './dataSetupContainerPart/DataDumpDrawer';
import lodashValues from 'lodash/values';
import { MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
    createReportSection:{
        padding: '0px',
        margin: '16px 0px 0px 0px',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
    },
    title: {
        height: '16px',
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '16px',
    },
    moreOutlined: {
        zIndex: 0,
        cursor:'pointer',
        right:'0',
    },
    sectionContainer: {
        display: 'flex',
        width: '100%',
        marginBottom: '12px',
        position: 'relative',
    },
    containerHeading: {
        fontSize: '10px',
        fontWeight: '400',
        color: '#8C8C8C',
    },
    dummyDataSwitch: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '12px',
    },
});

const DataSetupSection = (props) => {
    const { t } = useTranslation();
    const [customColumnType, setCustomColumnType] = useState<string>('');
    const [renderDataDumpDrawer, setRenderDataDumpDrawer] = useState(false);
    const [columnData, setColumnData] = useState({});
    const {
      aggregate,
      filters,
      sortBy,
      setReset,
      havingFilters,
      initialSuggestedFilters = [],
      isEditing,
      underlyingMetrics,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const filterData = useSelector((state:any) => state.filterData);
    const selectedDbType = useSelector((state:any) => state.reportMetaData.selectedDbType);
    const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
    const havingFilterData = useSelector((state:any) => state.havingFilterData);
    const isInternalSupportEmployee = useSelector((state:any) => state?.masterData?.userConfig?.isInternalSupportEmployee);
    const isDefaultEditable = useSelector((state:any) => state.masterData?.organisationConfig?.isDefaultEditable || false);
    const columnsMap = useSelector((state:any) => customColumnType==='global'?state.customMetrices.global
                                                    :customColumnType==='local'?state.customMetrices.local:null);
    const columns = lodashValues(columnsMap);
    const [visible, setVisible] = useState(false);
    const [enableDummyData, setEnableDummyData] = useState(false);
    const { reportObject } = props;

    const disableDummyData = () => {
        dispatch({
            type: DUMMY_DATA_SWITCH,
            payload: {
                enableDummyData: false,
            },
        });
    };
    useEffect(() => {
        disableDummyData();
    },[]);
    const onChangeRadio = (metricsName) => (value) => dispatch({
        type: FILTER_RADIO_SELECTED,
        payload: {
            metricsName,
            selectedFilter: value,
        },
    });
    const onChangeFilterData = (metricsName) => (data, additionalFiterData = null) => {
        const payload = { metricsName, data: { value:data } };
        if(additionalFiterData){
            payload.data['additionalFiterData'] = additionalFiterData;
        }
        return dispatch({
            type: FILTER_SELECTED_VALUE_CHANGED,
            payload,
        });
    };
    const onChangeHavingRadio = (metricsName) => (value) => dispatch({
        type: HAVING_FILTER_RADIO_SELECTED,
        payload: {
            metricsName,
            selectedFilter: value,
        },
    });
    const onChangeHavingFilterData = (metricsName) => data => dispatch({
        type: HAVING_FILTER_SELECTED_VALUE_CHANGED,
        payload: {
            metricsName,
            data:{value:data},
        },
    });
    const onChangeHavingAggregate = (name, value) => {
        dispatch({
          type: CHANGE_HAVING_AGGREGATE,
          payload: {name, value},
        });
      };
    const onChangeColumnDataGlobal = (key, data) => {
        dispatch({
        type: SET_CUSTOM_COLUMNS_GLOBAL,
        payload: {
            name: key,
            body: data,
        },
    });};

    const onRemove = (metric) => {
        underlyingMetrics.onRemoveUnderlyingMetrics(metric.name);
        aggregate.metricsMetaData.onRemoveMetrics(metric.name);
        filters.onRemoveFilter(metric.name);
        if(havingFilters?.onRemoveFilter){
            havingFilters.onRemoveFilter(metric.name);
        }
        aggregate.onRemoveSummarisation(metric.name);
        dispatch({
            type: REMOVE_CUSTOM_COLUMNS_GLOBAL,
            payload: {
                name: metric.id,
            },
        });
    };

    const onChangeColumnDataLocal = (key,data) => dispatch({
        type: SET_CUSTOM_COLUMNS_LOCAL,
        payload: {
            name: key,
            body: data,
        },
    });

    const onRemoveLocal = (metric) => dispatch({
        type: REMOVE_CUSTOM_COLUMNS_LOCAL,
        payload: {
            name: metric.id,
        },
    });

    useEffect(() => {
        setVisible(customColumnType?true:false);
    },[customColumnType]);

    const floatingButton = (
        <Button onClick={() => setCustomColumnType('global')}> {t('Custom Metrices')} </Button>
    );

    const onChangeDummyDataSwitch = () => {
        dispatch({
            type: DUMMY_DATA_SWITCH,
            payload: {
                enableDummyData: !enableDummyData,
            },
        });
        setEnableDummyData(prev => !prev);
    };
    return <>
        <div style={{ width: '100%' }}>
        <div className={classes.sectionContainer}>
            <div className={classes.containerHeading}>
                {t('Pick and choose the data elements here')}
            </div>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
            <Popover placement="leftTop" trigger="click" content={floatingButton} getPopupContainer={(trigger: any) => trigger.parentNode}>
                <MoreOutlined className={classes.moreOutlined}/>
            </Popover>
            </div>
        </div>
        {(isInternalSupportEmployee || isDefaultEditable) ?
            <div className={classes.dummyDataSwitch}>
                <div>{t('Dummy Data')}</div>
                <Switch size={'small'} checked={enableDummyDataSwitch}
                checkedChildren={'ON'} unCheckedChildren={'OFF'} onChange={onChangeDummyDataSwitch}/>
            </div>
        :null}
        <CustomColumnDrawer
            setReset={setReset}
            visible={visible}
            setCustomColumnType={setCustomColumnType}
            columns={columns}
            customColumnType={customColumnType}
            onChange={customColumnType==='global'?onChangeColumnDataGlobal:
                            customColumnType==='local'?onChangeColumnDataLocal:null}
            reportObject={reportObject}
            onRemove={customColumnType==='global'?onRemove:
                            customColumnType==='local'?onRemoveLocal:null}/>
        {
            renderDataDumpDrawer ?
            <DataDumpDrawer
                setRenderDataDumpDrawer={setRenderDataDumpDrawer}
                dataDumpRender={renderDataDumpDrawer}
                columnData={columnData}
            />
            : null
        }
        <FiltersSection
                initialSuggestedFilters={initialSuggestedFilters}
                filterData={filterData}
                aggregate={aggregate}
                onChangeRadio={onChangeRadio}
                onChangeFilterData={onChangeFilterData}
                filters={filters}
                isEditing={isEditing}
            />
        <SummariseBy aggregate={aggregate}/>
        <ColumnsSection
            setCustomColumnType={setCustomColumnType}
            aggregate={aggregate}
            setRenderDataDumpDrawer={setRenderDataDumpDrawer}
            setColumnData={setColumnData}
            enableDummyData={enableDummyData}
            />
        <SortBySection sortBy={sortBy}/>
        {aggregate.selectedAggregate.length && selectedDbType !== 'mongo' ? (
            <HavingSection
                havingFilterData={havingFilterData}
                aggregate={aggregate}
                onChangeHavingRadio={onChangeHavingRadio}
                onChangeHavingFilterData={onChangeHavingFilterData}
                havingFilters={havingFilters}
                onChangeHavingAggregate={onChangeHavingAggregate}/>
        ) : null}
        </div>
    </>;
};

export default memo(DataSetupSection);
