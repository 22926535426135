import { Select, Switch } from 'antd';
import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { useToggle } from 'src/components/common/hooks/toggle';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import PopOverPane from './popOverPane';
import FilterMenu from 'src/components/common/FilterComponent/SingleSelectionMenu';
import { useDispatch, useSelector } from 'react-redux';
import {
  BUCKET_VALUE_CHANGED,
  DRILLDOWN_VALUE_CHANGED,
} from 'src/reduxActions/actionNameEnums';
import lodashIsEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
    switchStyle:{
        marginRight: '10px',
    },
    switchContainer: {
        // marginTop: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    aggregate: {
        width: '50px',
    },
});

const SummarizeBy = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { aggregate, description, resetAggregate } = props;
    const dispatch = useDispatch();
    const initialValues = aggregate.selectedAggregate.map(data => data.value).filter((val) => val.section == null);
    const filterData = useSelector((state: any) => state.summarization);
    const selectedChartType = useSelector(
      (state: any) => state.chartsMetaData.chartType,
    );
    const selectedReportType = useSelector((state: any) => state.reportMetaData.selectedReport);
    const selectedDbType: any = useSelector(
      (state: any) => state.reportConfig?.reports[selectedReportType]?.dbType,
    );
    const bucketValueHandlerGenerator = (metricName) => (value) => {
        dispatch({ type: BUCKET_VALUE_CHANGED, payload: {metricName, bucketValue: value[0].key}});
    };
    const filteredAggregate = aggregate.selectedAggregate.filter((val) => val.section == null);
    const selectedReport = useSelector((state:any) => state.reportMetaData.selectedReport);
    const metrics = useSelector((state:any) => state.reportConfig.reports?.[selectedReport]?.metrics) || [];
    return (<SectionPartContainer
                title={t('Summarise By')}
                description={t(description)}
                onChangeSelect={aggregate.onChangeAggregateSelection}
                options={aggregate.aggregateOptions.map(
                  option => ({ ...option, prettyName: t(option.prettyName), description: t(option.description) }))}
                initialValue={initialValues}
                showCount>
                {filteredAggregate
                    .map(row => {
                        const currentMetric = metrics.find((metric) => metric.value === row.value);
                        const operations = currentMetric?.groupBy?.buckets ? currentMetric.groupBy?.buckets : [];
                        const bucketValue = filterData[row.value]?.groupBy.bucketValue;
                        const bucketOption = operations.find(bucket => bucket.value === bucketValue);
                        const defaultSelection = {
                          value: '',
                          prettyName: 'Select',
                        };
                        const showDrilldown =
                          !lodashIsEmpty(currentMetric.drilldownMetaData) &&
                          selectedChartType === 'pivotTable' &&
                          ['postgresql', 'redshift'].includes(selectedDbType);
                        const optionsValue = operations.length ?
                            operations.find(element => element.value === bucketValue) : [];
                        const handleDrilldown = (val) => {
                          dispatch({
                            type: DRILLDOWN_VALUE_CHANGED,
                            payload: {
                              metricName: row.value,
                              drilldown: {
                                type: currentMetric.drilldownMetaData.type,
                                toApply: val,
                              },
                            },
                          });
                        };
                        return (
                          <RowContainer
                            onRemove={() =>
                              aggregate.onRemoveSummarisation(
                                row.value,
                                resetAggregate,
                              )
                            }
                            showDrilldown={showDrilldown}
                            drilldownData={row.drilldown}
                            handleDrilldown={handleDrilldown}
                            title={row.prettyName}
                            description={row.description}
                            showLeftPane={operations.length !== 0}
                            LeftPaneComponent={PopOverPane}
                            leftPaneProps={{
                              Content: (
                                <FilterMenu
                                  options={operations}
                                  value={bucketOption}
                                  onChange={bucketValueHandlerGenerator(
                                    row.value,
                                  )}
                                />
                              ),
                              value: optionsValue || defaultSelection,
                            }}
                          />
                        );})}
                <div className={classes.switchContainer}>
    </div>
    </SectionPartContainer>);
};

export default memo(SummarizeBy);
