import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import CustomSelect from 'src/components/common/CustomSelect';
import { useLazyQuery } from '@apollo/client';
import { GETDATADICTIONARY } from 'src/components/pagesv2/queryBuilder/hooks/queries';
import { urlDownload } from 'src/components/pages/chartBuilder/helpers';
import { useSelector } from 'react-redux';
import { HTML_EDITOR_REPORT_TYPE } from 'src/constants';
import { isManualQueryReport } from 'src/components/common/utils/utils';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
    topBar: {
        width:'calc(100% - 1rem)',
        height: '45px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    cardTitle: {
        fontSize: '1rem',
        marginLeft: '1rem',
        fontWeight: '500',
        lineHeight: '1rem',
        color: '#262626',
    },
    divider: {
        margin: '0px',
    },
    resetAll: {
        color: 'red',
    },
    topQueryBarContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0.8rem 0.5rem',
        justifyContent: 'space-between',
        borderRadius: '4px 4px 0px 0px',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        alignItems: 'center',
        zIndex: 1,
    },
});

const CreateQueryTopBar = (props) => {
    const { t } = useTranslation();
    const { reportObject, setReset } = props;
    const allReportData = useSelector(
      (state: any) => state.reportConfig.reports,
    );
    const classes = useStyles();
    const onChangeHandle = (selectedReport) => {
        reportObject.setSelectedReport(selectedReport);
        reportObject.setSelectedReportDbType(allReportData[selectedReport].dbType);
        reportObject.setDataMartType(allReportData[selectedReport].type);
        setReset(selectedReport);
    };
    const [buttonLoading, setButtonLoading] = useState(false);
    const [getDataDictionary, { data }] = useLazyQuery(GETDATADICTIONARY, {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        urlDownload(data?.getDataDictionary.url);
        setButtonLoading(false);
      },
    });
    const showDownloadButton = reportObject.selectedReport !== HTML_EDITOR_REPORT_TYPE &&
      !isManualQueryReport(reportObject.selectedDataMartType);

    return (
      <>
        <div className={classes.topQueryBarContainer}>
          <div style={{ fontSize: '0.9rem' }}>{t('Pick Data')}</div>
          <CustomSelect
            style={{ width: showDownloadButton ? '65%': '80%' }}
            noLabel={true}
            size="medium"
            data={{ options: reportObject.reportList }}
            value={reportObject.selectedReport}
            onChange={onChangeHandle}
            dropdownStyle={{ minWidth: '25%' }}
            showSearch={true}
          />
          {showDownloadButton && (
            <div>
              <Tooltip title={t('Download Data Dictionary')}>
                <Button
                  loading={buttonLoading}
                  onClick={() => {
                    setButtonLoading(true);
                    getDataDictionary();
                  }}
                  download
                >
                  {<DownloadOutlined />}
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </>
    );
};
export default CreateQueryTopBar;
