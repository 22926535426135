import { Button, Modal, Row } from 'antd';
import React, { memo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import CustomSelect from 'src/components/common/CustomSelect';
import CustomInput from 'src/components/common/UIComponents/CustomInput';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { importTypes } from 'src/constants';
import { IMPORT_DASHBOARD, IMPORT_REPORT } from 'src/api/routes';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
  rows: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '6%',
  },
  import: {
    flex: '25%',
    padding: '1% 4% 1% 0%',
  },
  input: {
    flex: '75%',
  },
  uploadOuterBox: {
    position: 'relative',
    flex: '75%',
    height: '200px',
    border: '1px solid #ccc',
    borderRadius: '1%',
    padding: '3%',
    textAlign: 'center',
  },
  uploadInner: {
    height: '80%',
  },
  iconInbox: {
    fontSize: '50px',
    color: '#B8B8B8',
  },
});

const ImportModal = (props) => {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const classes = useStyles();
  const { Dragger } = Upload;
  const [importType, setImportType] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isUploaded, setIsUploaded] = useState(false);
  const [data, setData] = useState<any>('');

  const [importDashboard, queryResultDashboard] = useLazyAPI(IMPORT_DASHBOARD, {
    type: 'POST',
    onCompleted: (data) => {
      message.success(t('Imported Succssfully'));
    },
    onError: (data) => {
      message.error({
        key: 'importDashboard',
        duration: 3,
        content: t('Failed fetching dashboard data'),
      });
    },
  });

  const [importReport, queryResultReport] = useLazyAPI(IMPORT_REPORT, {
    type: 'POST',
    onCompleted: (data) => {
      message.success(t('Imported Succssfully'));
    },
    onError: (data) => {
      message.error(t('Failed to Import Report'));
    },
  });

  const onClickSubmit = () => {
    if (!name || !description) {
      message.error(t('Please fill all the fields'));
      return;
    }
    if (importType === importTypes.DASHBOARD) {
      importDashboard({
        variables: {
          dashboardName: name,
          description,
          data,
        },
      });
    } else if (importType === importTypes.REPORT) {
      importReport({
        variables: {
          reportName: name,
          description,
          data,
        },
      });
    }
    message.loading({ key: 'import', content: t('Importing...') });
    setOpen(false);
  };

  const importOptions = {
    options: Object.keys(importTypes).map((key) => ({
      value: importTypes[key],
      prettyName: importTypes[key],
    })),
  };

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        setData(e.target?.result);
        setIsUploaded(true);
        message.success(t('File uploaded successfully'));
      };
      reader.readAsText(file);
      return false;
    },
  };

  return (
    <Modal
      title={t('Import')}
      visible={open}
      width={'45%'}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          {t('Cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={onClickSubmit}>
          {t('Submit')}
        </Button>,
      ]}
    >
      <Row className={classes.rows}>
        <div className={classes.import}>{t('Import Type')}</div>
        <div className={classes.input}>
          <CustomSelect
            style={{ width: '100%' }}
            noLabel={true}
            size="medium"
            data={importOptions}
            value={importType}
            onChange={(value) => setImportType(value)}
            showSearch={true}
          />
        </div>
      </Row>
      <Row className={classes.rows}>
        <div className={classes.import}>{t('Select File')}</div>
        <div className={classes.uploadOuterBox}>
          <div className={classes.uploadInner}>
            <Dragger {...uploadProps}>
              <InboxOutlined className={classes.iconInbox} />
              <p className="ant-upload-text">
                {t('Click or drag file to this area to upload')}
              </p>
            </Dragger>
          </div>
        </div>
      </Row>
      <Row className={classes.rows}>
        <div
          className={classes.import}
          style={{ color: !isUploaded ? '#B8B8B8' : '#000000' }}
        >
          {t('Name')}
        </div>
        <div className={classes.input}>
          <CustomInput
            disabled={!isUploaded}
            onChange={(value) => setName(value)}
            placeholder={t(`Give this ${importType} a name`)}
            value={name}
          />
        </div>
      </Row>
      <Row className={classes.rows}>
        <div
          className={classes.import}
          style={{ color: !isUploaded ? '#B8B8B8' : '#000000' }}
        >
          {t('Description')}
        </div>
        <div className={classes.input}>
          <CustomInput
            disabled={!isUploaded}
            style={{ height: '6.5rem' }}
            onChange={(value) => setDescription(value)}
            placeholder={t('Start typing...')}
            value={description}
          />
        </div>
      </Row>
    </Modal>
  );
};

export default memo(ImportModal);
