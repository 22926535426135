import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FilterMenu = (props) => {
    const { t } = useTranslation();
    const { options, onChange, value } = props;
    const menu = <Menu onClick={(value) => onChange([value])} selectedKeys={[value?.value]}>
                    {options.map(option => <Menu.Item key={option.value}>{t(option.prettyName)}</Menu.Item >)}
                 </Menu>;
    return menu;
};

export default FilterMenu;
