import React, { memo, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import CustomInput from 'src/components/common/customInput';
import CustomRadioGroup from 'src/components/common/customRadio';
import lodashCamelcase from 'lodash/camelCase';
import { CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const useStyle = createUseStyles({
    basePane: {
        backgroundColor: '#FAF7EE',
        padding: '8px',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
        marginBottom: '10px',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
});

const converPrettyNameToMetric = (string) => {
    return lodashCamelcase(string
        .toLowerCase()
        .split('')
        .filter(c => (c >= 'a' && c <= 'z') || c === ' ')
        .join(''));
};

const CustomColumnsDataCard =   (props) => {
    const { t } = useTranslation();
    const { onChange, data, onRemove } = props;
    const classes = useStyle();
    const [prettyName, setPrettyName] = useState(data.prettyName);
    const [type, setType] = useState(data.type);
    const [sql, setSql] = useState(data.sql);

    const typeOptions = [
        {value: 'number', prettyName: t('Number')},
        {value: 'text', prettyName: t('Text')},
        {value: 'boolean', prettyName: t('Boolean')},
        {value: 'timestampz', prettyName: t('Timestamp')},
        {value: 'date', prettyName: t('Date')},
    ];

    useEffect(() => {
         const { prettyName, type, sql } = data;
         setPrettyName(prettyName);
         setType(type);
         setSql(sql);
     }, [data]);

    useEffect(() => {
        onChange({
            name: converPrettyNameToMetric(prettyName),
            prettyName,
            type,
            sql,
            id: data.id,
        });
    },[prettyName, type, sql]);
    // if you select any text in sqlInputEditor and paste after selection then onChange will be called
    // twice once for cut and second for paste and setState will stuck in a infinite loop
    // to prevent this problem
    const lazyUpdate = () => {
        let sqlValue = '';
        let inside = false;
        return (value) => {
            sqlValue = value;
            if(inside === false){
                inside = true;
                setTimeout(() => {
                    setSql(sqlValue);
                    inside = false;
                });
            }
        };
    };
    return <div className={classes.basePane}>
        <div className={classes.closeButton}>
            <CloseCircleFilled onClick={onRemove} style={{ cursor: 'pointer', fontSize: '16px', color: 'rgba(0,0,0,0.3)' }} />
        </div>
        <CustomInput onChange={setPrettyName} prettyName={t('Pretty Name')} value={prettyName} placeholder={t('Column Name')}/>
        <CustomRadioGroup onChange={setType} prettyName={t('Column Type')} value={type} data={{options:typeOptions}} direction={'horizontal'}/>
        <CustomInput onChange={lazyUpdate()} prettyName={t('Sql')} value={sql} placeholder={t('Please Input metric Sql')} isCodeEditor={true}/>
    </div>;
};

export default memo(CustomColumnsDataCard);
