import { Drawer, Affix, Input, Col, Row, DatePicker, TimePicker, InputNumber, Popover, Form, Button, Select, message, Empty, Spin } from 'antd';
import React, { useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import lodashCapitalize from 'lodash/capitalize';
import lodashValues from 'lodash/values';
import lodashFilter from 'lodash/filter';
import lodashUniqBy from 'lodash/uniqBy';
import CustomSelect from 'src/components/common/CustomSelect';
import CustomInput from 'src/components/common/customInput';
import DrawerTitleBar from '../../../common/DrawerTitleBar';
import { EditOutlined, InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { mailTypes, intervals, frequencyTypes, entityTypes, timeFormat, dateFormat, dateTimeFormat } from '../../../../constants';
import moment from 'moment-timezone';
import { generateIntervalOptions } from '../../../common/utils/schedulerAlertsUtils';
import { useLazyQuery } from '@apollo/client';
import { GET_DASHBOARD_GROUP_REPORTS_FOR_EMAIL } from '../reportsQuery';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DragSVG } from 'src/assets/drag.svg';
import {
    getCustomNumberTemplate,
    getSingleNumberTemplate,
    getTableTemplate,
    getThreeNumberTemplate,
    getHeader,
    getTopBanner,
    getSummary,
    getFooter,
  } from '../utils';
import LoadingComponent from 'src/components/common/LoadingComponent';
import { useDispatch, useSelector } from 'react-redux';
import DynamicFormatting from './DynamicFormatting';
import { getProjectName } from 'src/components/common/utils/utils';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { GET_USER_ROLES } from 'src/api/routes';
import { SET_USER_ROLES } from 'src/reduxActions/actionNameEnums';
import { useTranslation } from 'react-i18next';

const { CUSTOM } = intervals;
const { REPORT, CXO } = entityTypes;
const { TEXT, MJML } = mailTypes;
const FormItem = Form.Item;
const FormItemLabel = Form;

const useStyle = createUseStyles({
    basePane: {
        width: '600px',
    },
    formContainer: {
        padding: '16px',
    },
    fillInText: {
        color: '#262626',
        fontSize: '14px',
        marginBottom: '16px',
    },
    divider: {
        marginBottom: '20px',
    },
    titleStyle: {
        lineHeight: '14px',
        letterSpacing: '0px',
        fontWeight: '600',
        color: '#303030',
        height: '14px',
        marginBottom: '0.5rem',
        fontSize: '12px',
    },
    contentBox: {
        backgroundColor: '#FAFAFA',
        borderRadius: '4px',
        padding: '10px 16px',
        marginTop: '16px',
        marginBottom: '16px',
    },
    prettyName: {
        lineHeight: '14px',
        letterSpacing: '0px',
        color: '#303030',
        height: '14px',
        marginBottom: '0.5rem',
        fontSize: '12px',
    },
    center: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    heading: {
        marginTop: '4px',
        marginBottom: '20px',
    },
    customColumnPane: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    formItemLabel: {
        lineHeight: '14px',
        letterSpacing: '0px',
        color: '#303030',
        height: '14px',
        fontSize: '12px',
    },
    dragContainer: {
        display: 'flex',
        gap: '1rem',
        backgroundColor: '#fff',
        padding: '0.5rem 1rem',
        borderBottom: '1px solid #D9D9D9',
    },
    formSubTitle: {
        fontSize: '10px',
        color: 'rgba(0, 0, 0, 0.45)',
        marginLeft: '0.5rem',
    },
    spinDisplay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
  },
    affixStyle: {
      position: 'sticky',
      top: '0px',
      zIndex: '2',
    },
});

const frequecyOptions = lodashValues(frequencyTypes).map((value) => {
    return { value, prettyName: lodashCapitalize(value)};
});

const SchedulerDrawer = (props) => {
    const { t } = useTranslation();
    const { visible, onClose, data, editMetaData, entityType } = props;
    const classes = useStyle();
    const [selectShow, setSelectShow] = useState(false);
    const dispatch = useDispatch();
    const [summarySubjectDataShow, setSummarySubjectDataShow] = useState(false);
    const projectName = getProjectName();
    const scheduledEmailReportLimit = useSelector(
        (state: any) => state.masterData?.organisationConfig?.scheduledEmailReportLimit,
    );
    const config = useSelector((state: any) => state.config);
    const { roleOptions } = config;
    const [isSummaryDrawerVisible, setIsSummaryDrawerVisible] = useState(false);
    const [isSubjectDrawerVisible, setIsSubjectDrawerVisible] = useState(false);
    const [sendTestEmailHoverText, setSendTestEmailHoverText] = useState('');
    const reportMailTypeOptions = [
      {
          value: TEXT,
          prettyName: t('Report Links'),
      },
  ];
  
  const dashboardMailTypeOptions = [
      {
          value: TEXT,
          prettyName: t('Report Links'),
      },
      {
          value: MJML,
          prettyName: t('Summary snapshot (Beta)'),
      },
  ];

    const [getUserRoles, { loading: loadingUserRoles }] = useLazyAPI(GET_USER_ROLES, {
      type: 'GET',
      onCompleted: (data) => {
        dispatch({ type: SET_USER_ROLES, payload: data });
      },
      onError: (data) => {
        message.error({
          key: 'ReportStatus',
          duration: 0.5,
          content: t('Failed to fetch user roles'),
        });
      },
    });

    const [getDashboardGroupReportsForEmail, {loading: loadingGroupReports}] = useLazyQuery(GET_DASHBOARD_GROUP_REPORTS_FOR_EMAIL, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            const currEntityList = editMetaData?.selectedEntityList;
            if(currEntityList) {
                const mergedObjects = currEntityList.map(obj1 => {
                    const matchingObj = response.getDashboardGroupReportsForEmail.reports.find(obj2 => obj2.id === obj1.entityId);
                    if(matchingObj) return { ...obj1, ...matchingObj, value: matchingObj.id, label: matchingObj.name };
                }).filter(obj => obj);
                data.setSelectedEntityList(mergedObjects);
            }
            if (editMetaData?.selectedEntityListForSubjectAndSummary) {
              const mergedObjects =
                editMetaData?.selectedEntityListForSubjectAndSummary
                  .map((obj1) => {
                    const matchingObj =
                      response.getDashboardGroupReportsForEmail.reports.find(
                        (obj2) => obj2.id === obj1.entityId,
                      );
                    if (matchingObj) {
                      return {
                        ...obj1,
                        ...matchingObj,
                        value: matchingObj.id,
                        label: matchingObj.name,
                      };
                    }
                  })
                  .filter((obj) => obj);
              data.setSelectedEntityListForSubjectAndSummary(mergedObjects);
            }
            data.setDashboardReports(
                response.getDashboardGroupReportsForEmail.reports,
            );
            data.setHandlers(response.getDashboardGroupReportsForEmail.handlers);
            data.setTags(JSON.parse(response.getDashboardGroupReportsForEmail.tags || '[]'));
        },
    });

    const getDynamicChartTemplate = (type, objPath) => {
        switch(type) {
            case 'singleNumber':
                return getSingleNumberTemplate(objPath);
            case 'customNumber':
                return getCustomNumberTemplate(objPath);
            case 'threeNumber':
                return getThreeNumberTemplate(objPath);
            case 'table':
                return getTableTemplate(objPath);
            default:
                return;
        }
    };

    const getMJMLTemplate = (dynamicTemplate) => {
        return `<mjml>
                ${getHeader()}
                    <mj-body>
                        ${getTopBanner()}
                        ${getSummary()}
                        ${dynamicTemplate}
                        ${getFooter()}
                    </mj-body>
                </mjml>`;
    };

    React.useEffect(() => {
    if (visible && data.mailType === MJML && entityType !== CXO && entityType !== REPORT) {
        getDashboardGroupReportsForEmail({ variables: { dashboardGroupId: data.entityId } });
    }

      if (visible && roleOptions === null) {
        getUserRoles();
      }
      if (data.mailType === MJML) {
        setSendTestEmailHoverText(t('If no roles selected, email will be sent to schedule creator'));
      } else {
        setSendTestEmailHoverText('');
      }
    }, [visible, data.mailType]);

    const reorderSortMetrics = (result) => {
        const newMetricList = [...data.selectedEntityList];
        const [removed] = newMetricList.splice(result.source.index, 1);
        newMetricList.splice(result.destination.index, 0, removed);
        const newList = newMetricList.map((item) => item);
        data.setSelectedEntityList(newList);
        generateOutput(newList);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
        reorderSortMetrics(result);
    };

    const flipSelectShow = () => {
        setSelectShow(!selectShow);
    };

    const flipSelectShowForSummarySubjectData = () => {
      setSummarySubjectDataShow(!summarySubjectDataShow);
    };

    const generateOutput = (newList, callbackFn = () => {}) => {
        if (!newList.length) {
            data.setMessage('');
            callbackFn();
            return;
        }
        let output = '';
        newList.map((report) => {
            const objPath = findObjectPath(data.showcaseTemplate, report.name);
            const dynamicTemplate = getDynamicChartTemplate(report.templateKey, objPath);
            output = output + dynamicTemplate;
        });

        data.setMessage(getMJMLTemplate(output));
        callbackFn();
    };

    const getReportOptions = () => {
        return data.dashboardReports.map((report) => {
            return {
                label: report.name,
                key: report.id,
                value: report.id,
                ...report,
            };
        });
    };

    const getReportOptionsForSubjectAndSummary = () => {
      const reports: any = [];
      data.dashboardReports.forEach((report) => {
            if (report.templateKey === 'singleNumber') {
              reports.push({
                label: report.name,
                key: report.id,
                value: report.id,
                ...report,
              });
            }
      });
      return reports;
    };

    const findObjectPath = (obj, name, path = '') => {
        for (const [key, value] of Object.entries(obj)) {
          if (key === 'name' && value === name) {
            return path;
          }

          if (typeof value === 'object') {
            const result = findObjectPath(value, name, `${path}${path ? '.' : ''}${key}`);
            if (result) {
              return result;
            }
          }
        }

        return null;
    };

    const handleEmailUpdate = (e) => {
        const str = e.target.value;
        data.setEmailRecipients(str);
    };

    const removeSelectedDashboardReport = (index) => {
        const newMetricList = [...data.selectedEntityList];
        newMetricList.splice(index, 1);
        data.setSelectedEntityList(newMetricList);
        generateOutput(newMetricList);
    };

    const removeSelectedDashboardReportForSubjectAndSummary = (index) => {
      const newEntityList = [...data.selectedEntityListForSubjectAndSummary];
      newEntityList.splice(index, 1);
      data.setSelectedEntityListForSubjectAndSummary(newEntityList);
    };

    const onSelectChange = (option) => {
        const tableReports = option.filter((entity) => entity.templateKey === 'table');
        if(tableReports.length > scheduledEmailReportLimit) {
            message.error(
                `${t('Maximum limit exceeded. Cannot add more than')} ${scheduledEmailReportLimit} ${t('table reports')}`,
            );
            return;
        }

        data.setSelectedEntityList(option);
        generateOutput(option);
        flipSelectShow();
    };

    const onSelectedSummarySubjectChange = (option) => {
      data.setSelectedEntityListForSubjectAndSummary(option);
      flipSelectShowForSummarySubjectData();
    };

    const roles = roleOptions?.map((role) => {
      return { value: role.role_id, label: role.role_name };
    });

    return (
      <>
        <Drawer
          width={700}
          zIndex={2}
          bodyStyle={{ padding: '0px' }}
          placement="right"
          closable={false}
          visible={visible}
        >
          <Affix offsetTop={1} className={classes.affixStyle}>
            <DrawerTitleBar
              onClickButton={data.onCreateScheduler}
              onClose={onClose}
              title={editMetaData ? t('Update Scheduler') : t('Create Scheduler')}
              buttonText={editMetaData ? t('Update') : t('Create')}
              secondaryButtonText={t('Send Test Email')}
              disableSecondaryButton={loadingGroupReports}
              onClickSecondaryButton={data.onSendTestEmail}
              secondaryButtonLoading={data.sendingTestEmail}
              secondaryButtonHoverTitle={sendTestEmailHoverText}
            />
          </Affix>
          <div className={classes.formContainer}>
            <div className={classes.fillInText}>
              {t('Fill in the details to create a scheduler')}
            </div>
            <div className={classes.contentBox}>
              <div className={classes.heading}>
                <h4>
                  {t('Scheduler Title')} <span style={{ color: 'red' }}>*</span>
                </h4>
              </div>
              <CustomInput
                onChange={data.setTitle}
                value={data.title}
                noLabel={true}
                mandatory
                placeholder={'Title Goes Here'}
              />
            </div>
            {entityType !== CXO ? (
              <div className={classes.contentBox}>
                <div className={classes.heading}>
                  <h4>
                    {t('Email Type')} <span style={{ color: 'red' }}>*</span>
                  </h4>
                </div>
                <div style={{ width: '50%', marginBottom: '20px', paddingRight: '30px' }}>
                  <CustomSelect
                    onChange={val => {
                      data.setMailType(val);
                      data.setMessage('');
                    }}
                    size={'medium'}
                    style={{width: '100%'}}
                    value={data.mailType}
                    noLabel={true}
                    mandatory
                    type={'singleSelect'}
                    data={{
                      options:
                        entityType === REPORT
                          ? reportMailTypeOptions
                          : dashboardMailTypeOptions,
                    }}
                  />
                </div>
              </div>
            ) : null}
            {projectName === 'exim' ? (
              <div className={classes.contentBox}>
                <div className={classes.heading}>
                  <h4>
                    {t('Email IDs')} <span style={{ color: 'red' }}>*</span>
                    <span
                      style={{
                        fontSize: '10px',
                        color: 'rgba(0, 0, 0, 0.45)',
                        marginLeft: '0.5rem',
                      }}
                    >
                      {t('Add Email IDs separated by comma')}
                    </span>
                  </h4>
                </div>
                <div>
                  <Row gutter={[6, 6]}>
                    <Input.TextArea
                      value={data.emailRecipients}
                      onChange={handleEmailUpdate}
                    ></Input.TextArea>
                  </Row>
                </div>
              </div>
            ) : (
              <div>
                {data.mailType === MJML ? (
                  <div className={classes.contentBox}>
                    <div className={classes.heading}>
                      <h4>
                        {t('Recipient Roles')} <span style={{ color: 'red' }}>*</span>
                      </h4>
                    </div>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={t('Please select roles')}
                      onChange={data.setSelectedRoles}
                      value={data.selectedRoles}
                      options={roles}
                      loading={loadingUserRoles}
                    />
                  </div>
                ) : null}
              </div>
            )}
            <div className={classes.contentBox}>
              <div className={classes.heading}>
                <h4>{t('CC Employees')}</h4>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <Select
                  value={data.ccEmails}
                  searchValue={data.employeeSearchQuery}
                  loading={data.loadingEmployeeEmails}
                  onSearch={data.onEmployeeSearch}
                  onDropdownVisibleChange={data.onDropdownVisibleChange}
                  notFoundContent={
                    data.loadingEmployeeEmails ? (
                      <Spin className={classes.spinDisplay} size="small" />
                    ) : (
                      <Empty
                        imageStyle={{
                          height: '2rem',
                          display: 'flex',
                          justifyContent: 'center',
                          width: 'auto',
                        }}
                      />
                    )
                  }
                  style={{ width: '100%' }}
                  mode="multiple"
                  placeholder={t('Please select CC employees (Type more than 2 characters to search)')}
                  onChange={(selectedValues, selectedOptions: any) => {
                    const selectedFilteredCCEmails = lodashFilter(
                      lodashUniqBy(
                        [...data.ccEmails, ...selectedOptions],
                        'value',
                      ),
                      emailObject => selectedValues.includes(emailObject.value),
                    );
                    data.setCCEmails(selectedFilteredCCEmails);
                  }}
                  options={data.employeeOptions}
                  filterOption={false}
                  allowClear
                />
              </div>
            </div>
            <div className={classes.contentBox}>
              <div className={classes.heading}>
                <h4>
                  {t('SCHEDULING')}
                  <span
                    style={{
                      fontSize: '10px',
                      color: 'rgba(0, 0, 0, 0.45)',
                      marginLeft: '0.5rem',
                    }}
                  >
                    {t('All emails scheduled as per local time')}
                  </span>
                </h4>
              </div>
              <div className={classes.divider}>
                <Row gutter={[6, 6]}>
                  <Col span={12}>
                    <span className={classes.prettyName}>
                      {t('Schedule')} <span style={{ color: 'red' }}>*</span>
                    </span>
                  </Col>
                  <Col span={12}>
                    <span className={classes.prettyName}>
                      {t('Frequency')} <span style={{ color: 'red' }}>*</span>
                    </span>
                  </Col>
                </Row>
                <Row gutter={[6, 6]}>
                  <Col span={12}>
                    <Row gutter={[6, 6]}>
                      <Col span={12}>
                        <DatePicker
                          value={data.startDate}
                          onChange={data.onChangeDate}
                          format={dateFormat}
                        />
                      </Col>
                      <Col span={12}>
                        <TimePicker
                          value={data.startTime}
                          onSelect={data.onChangeTime}
                          format={timeFormat}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <div className={classes.customColumnPane}>
                      <CustomSelect
                        onChange={data.setSelectedInterval}
                        size={'medium'}
                        noLabel={true}
                        value={data.selectedInterval}
                        type={'singleSelect'}
                        data={{
                          options: generateIntervalOptions(data.startTimestamp),
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              {data.selectedInterval === CUSTOM ? (
                <div className={classes.divider}>
                  <Row gutter={[6, 6]}>
                    <Col span={6}>
                      <span className={classes.prettyName}>{t('Repeats Every')}</span>
                    </Col>
                    <Col span={6} />
                    <Col span={3}>
                      <div className={classes.center}>
                        <span className={classes.prettyName}>{t('Ends')}</span>
                      </div>
                    </Col>
                    <Col span={9} />
                  </Row>
                  <Row gutter={[6, 6]}>
                    {entityType === CXO ? (
                      <Col span={12}>
                        <div className={classes.customColumnPane}>
                          <CustomSelect
                            onChange={data.setFrequency}
                            size={'medium'}
                            noLabel={true}
                            value={data.frequency}
                            type={'singleSelect'}
                            data={{ options: frequecyOptions }}
                          />
                        </div>
                      </Col>
                    ) : (
                      <>
                        <Col span={6}>
                          <InputNumber
                            style={{ width: '100%' }}
                            min={1}
                            max={12}
                            value={data.repeat}
                            onChange={data.setRepeat}
                          />
                        </Col>
                        <Col span={6}>
                          <div className={classes.customColumnPane}>
                            <CustomSelect
                              onChange={data.setFrequency}
                              size={'medium'}
                              noLabel={true}
                              value={data.frequency}
                              type={'singleSelect'}
                              data={{ options: frequecyOptions }}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                    <Col span={3}>
                      <div className={classes.center}>
                        <span className={classes.prettyName}>{t('On')}</span>
                      </div>
                    </Col>
                    <Col span={9}>
                      <DatePicker
                        onSelect={data.setEndTimestamp}
                        value={data.endTimestamp}
                        format={dateTimeFormat}
                        showTime
                      />
                    </Col>
                  </Row>
                  {data.frequency === 'hour' ? (
                    <Row style={{ marginTop: '10px' }} gutter={[6, 6]}>
                      <Col span={12}>
                        <TimePicker.RangePicker
                          format={timeFormat}
                          value={
                            data?.fromToRange?.timeRange?.length
                              ? [
                                  moment(
                                    data.fromToRange.timeRange[0],
                                    'HH:mm',
                                  ),
                                  moment(
                                    data.fromToRange.timeRange[1],
                                    'HH:mm',
                                  ),
                                ]
                              : null
                          }
                          onChange={data.onChangeTimeRange}
                          style={{ width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className={classes.contentBox}>
              <Popover
                trigger={'hover'}
                placement="topRight"
                content={
                  <div style={{ zIndex: 3, maxWidth: '400px' }}>
                    <pre>{JSON.stringify(data.showcaseTemplate, null, 2)}</pre>
                  </div>
                }
                title={'Content'}
              >
                <InfoCircleOutlined
                  style={{ float: 'right', cursor: 'pointer' }}
                />
              </Popover>
              <div className={classes.heading}>
                <h4>{t('EMAIL CONTENT')}</h4>
              </div>
              {entityType !== CXO &&
              entityType !== REPORT &&
              data.mailType === MJML ? (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '5px',
                    }}
                  >
                    <FormItemLabel className={classes.formItemLabel}>
                      {t('Data For summary and subject')}
                    </FormItemLabel>
                    <span className={classes.formSubTitle}>
                      {t('Please select big number reports with single column')}
                    </span>
                    <div
                      onClick={flipSelectShowForSummarySubjectData}
                      style={{ marginLeft: 'auto' }}
                    >
                      <PlusCircleOutlined
                        style={{ color: '#006EC3', cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                  {summarySubjectDataShow && (
                    <div>
                      <Select
                        mode="multiple"
                        value={data.selectedEntityListForSubjectAndSummary}
                        style={{ width: '100%' }}
                        onChange={(val, option: any) => {
                          onSelectedSummarySubjectChange(option);
                        }}
                        options={getReportOptionsForSubjectAndSummary()}
                        defaultOpen
                        autoFocus
                        filterOption={(input, option) =>
                          JSON.stringify(option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      ></Select>
                    </div>
                  )}
                  {loadingGroupReports ? (
                    <LoadingComponent />
                  ) : (
                    data.selectedEntityListForSubjectAndSummary.map(
                      (row, j) => {
                        return (
                          <div className={classes.dragContainer}>
                            <div>{row.label}</div>
                            <div
                              onClick={() =>
                                removeSelectedDashboardReportForSubjectAndSummary(
                                  j,
                                )
                              }
                              style={{ marginLeft: 'auto' }}
                            >
                              <MinusCircleOutlined
                                style={{ color: '#FF4D4F' }}
                              />
                            </div>
                          </div>
                        );
                      },
                    )
                  )}
                </div>
              ) : null}
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <FormItemLabel className={classes.formItemLabel}>
                    {t('Subject')} <span style={{ color: 'red' }}>*</span>
                  </FormItemLabel>
                  {data.mailType === MJML && (
                    <>
                      <span
                        style={{
                          fontSize: '10px',
                          color: 'rgba(0, 0, 0, 0.45)',
                          marginLeft: '0.5rem',
                        }}
                      >
                        {t('Construct message using Handlebars')}
                      </span>
                      <div style={{ marginLeft: 'auto' }}>
                        <EditOutlined
                          onClick={() =>
                            setIsSubjectDrawerVisible(!isSubjectDrawerVisible)
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
                <CustomInput
                  onChange={data.setSubjects}
                  value={data.subjects}
                  placeholder={t('Subject goes here')}
                />
              </div>
              {entityType !== CXO && data.mailType === MJML ? (
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormItemLabel className={classes.formItemLabel}>
                      {t('Summary')}
                    </FormItemLabel>
                    <span
                      style={{
                        fontSize: '10px',
                        color: 'rgba(0, 0, 0, 0.45)',
                        marginLeft: '0.5rem',
                      }}
                    >
                      {t('Construct message using Handlebars')}
                    </span>
                    <div style={{ marginLeft: 'auto' }}>
                      <EditOutlined
                        onClick={() =>
                          setIsSummaryDrawerVisible(!isSummaryDrawerVisible)
                        }
                      />
                    </div>
                  </div>
                  <CustomInput
                    onChange={data.setSummary}
                    value={data.summary}
                    placeholder={t('Summary goes here')}
                  />
                </div>
              ) : null}
              {entityType !== CXO &&
              entityType !== REPORT &&
              data.mailType === MJML ? (
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormItemLabel className={classes.formItemLabel}>
                      {t('Data for email body')}
                    </FormItemLabel>
                    <span className={classes.formSubTitle}>
                      {t('Please select only tables with upto 3 columns to ensure')}
                      {t('ideal viewing experience')}
                    </span>
                    <div
                      onClick={flipSelectShow}
                      style={{ marginLeft: 'auto' }}
                    >
                      <PlusCircleOutlined
                        style={{ color: '#006EC3', cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                  {selectShow && (
                    <div>
                      <Select
                        mode="multiple"
                        value={data.selectedEntityList}
                        style={{ width: '100%' }}
                        onChange={(val, option: any) => {
                          onSelectChange(option);
                        }}
                        options={getReportOptions()}
                        filterOption={(input, option) =>
                          JSON.stringify(option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        defaultOpen
                        autoFocus
                      ></Select>
                    </div>
                  )}
                  {loadingGroupReports ? (
                    <LoadingComponent />
                  ) : (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {provided => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ marginTop: '0.5rem' }}
                          >
                            {data.selectedEntityList.map((row, j) => {
                              return (
                                <>
                                  <Draggable
                                    key={row.value}
                                    draggableId={row.value}
                                    index={j}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        snapshot={snapshot}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className={classes.dragContainer}>
                                          <div>
                                            <DragSVG />
                                          </div>
                                          <div>{row.label}</div>
                                          <div
                                            onClick={() =>
                                              removeSelectedDashboardReport(j)
                                            }
                                            style={{ marginLeft: 'auto' }}
                                          >
                                            <MinusCircleOutlined
                                              style={{ color: '#FF4D4F' }}
                                            />
                                          </div>
                                        </div>
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Draggable>
                                </>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </div>
              ) : null}

              <div style={{ marginTop: '1.5rem' }}>
                <div style={{ display: 'flex' }}>
                  <FormItemLabel className={classes.formItemLabel}>
                    {t('Message')} <span style={{ color: 'red' }}>*</span>
                  </FormItemLabel>
                  {entityType !== CXO &&
                  entityType !== REPORT &&
                  data.mailType === MJML ? (
                    <div
                      onClick={() =>
                        generateOutput(data.selectedEntityList, () => {
                          message.success(
                            t('Message Successfully Reset to Default'),
                          );
                        })
                      }
                      style={{
                        cursor: 'pointer',
                        marginLeft: 'auto',
                        fontSize: '12px',
                        color: 'rgba(0, 0, 0, 0.45)',
                      }}
                    >
                      {t('Reset')}{' '}
                      <RedoOutlined
                        style={{
                          fontSize: '12px',
                          color: 'rgba(0, 0, 0, 0.45)',
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                {entityType !== CXO ? (
                  <CustomInput
                    isTextArea={data.mailType === TEXT ? true : false}
                    isCodeEditor={data.mailType === MJML ? true : false}
                    onChange={data.setMessage}
                    value={data.message}
                    placeholder={t('Message Goes Here')}
                    width={'100%'}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </Drawer>
        <DynamicFormatting
          title={t('Edit Subject')}
          visible={isSubjectDrawerVisible}
          onApply={val => {
            data.setSubjects(val);
            setIsSubjectDrawerVisible(false);
          }}
          handlers={data.handlers}
          tags={data.tags}
          selectedReports={data.selectedEntityListForSubjectAndSummary}
          handlerDrawerClose={() => setIsSubjectDrawerVisible(false)}
        />
        <DynamicFormatting
          title={t('Edit Summary')}
          visible={isSummaryDrawerVisible}
          onApply={val => {
            data.setSummary(val);
            setIsSummaryDrawerVisible(false);
          }}
          handlers={data.handlers}
          tags={data.tags}
          selectedReports={data.selectedEntityListForSubjectAndSummary}
          handlerDrawerClose={() => setIsSummaryDrawerVisible(false)}
        />
      </>
    );
};

export default SchedulerDrawer;
