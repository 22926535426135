import React, { useState } from 'react';
import { Button, Modal, Popover } from 'antd';
import DownloadPage from '../pages/downloadStatusPage';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_DOWNLOAD_MODAL_STATE } from 'src/reduxActions/actionNameEnums';
import { useTranslation } from 'react-i18next';

const DownloadModal: React.FC = () => {
  const { t } = useTranslation();
  const downloadModalVisibility = useSelector((state:any) => state.config.downloadModalVisibility);
  const dispatch = useDispatch();
  const onCancel = () => dispatch({
        type: CHANGE_DOWNLOAD_MODAL_STATE,
        payload: false,
    });

  // antd modal has default zIndex of 1000, giving this 1001 to keep it above the child report modal
  return (
    <>
      <Modal
        title={t('Downloads')}
        bodyStyle={{ paddingTop: '0' }}
        centered
        visible={downloadModalVisibility}
        onCancel={onCancel}
        width={800}
        zIndex={1001}
        footer={[
            <Button onClick={onCancel}>
              {t('Cancel')}
            </Button>,
          ]}
      >
        <DownloadPage />
      </Modal>
    </>
  );
};

export default DownloadModal;
