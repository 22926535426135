import { Input, InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

const { Option } = Select;

const useStyle = createUseStyles({
    betweenContainer: {
        width: '100%',
        flexDirection: 'row',
    },
    select: {
        width: '40%',
    },
});

const DatePreviousInput = (props) => {
    const { t } = useTranslation();
    const classes = useStyle();
    const { data, onChange, value, isVisible } = props;
    const options = data?.options || [];
    const [selectValue, setSelectValue] = useState(value?.value?.unit);
    const [inputValue, setInputValue] = useState(value?.value?.quantity);
    const [openSelect, setOpenSelect] = useState(false);
    useEffect(() => {
        if (selectValue !== undefined && inputValue !== undefined) {
          onChange({ unit: selectValue, quantity: inputValue });
        }
      }, [selectValue, inputValue]);

    const onchangeWithValidator = (event) => {
        const value = parseInt(event.target.value, 10);
        if(value){
            setInputValue(value);
        }
        else if(event.target.value === ''){
            setInputValue(null);
        }
    };
    return <div className={classes.betweenContainer}>
        <Input.Group compact>
            <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                onClick={() =>  setOpenSelect(!openSelect)}
                onBlur={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpenSelect(false);
                }}
                placeholder={t('Select Range')}
                className={classes.select}
                value={selectValue}
                open={openSelect}
                onChange={setSelectValue}>
                        {options.map(option => <Option value={option.value}>{t(option.prettyName)}</Option>)}
            </Select>
            <Input style={{width: '60%'}} value={inputValue} onChange={onchangeWithValidator}/>
        </Input.Group>
        </div>;
};

export default DatePreviousInput;
