import { Affix, Button, Drawer, message } from 'antd';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashValues from 'lodash/values';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import DrawerTitleBar from 'src/components/common/DrawerTitleBar';
import CustomColumnsDataCard from './customColumnDrawerParts/CustomColumnsDataCard';
import { v4 as uuidv4 } from 'uuid';
import { Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { METRICS_CHANGED, SET_REPORT_MASTER_DATA } from 'src/reduxActions/actionNameEnums';
import { getDownloadRowLimitWarningMessage } from '../chartBuilderUtils';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const useStyle = createUseStyles({
    descriptionText: {
        height: '12px',
        color: '#565656',
        marginBottom: '16px',
    },
    body: {
        padding: '16px',
    },
    addMoreButton: {
        color: '#1D6EC3',
        marginTop: '10px',
        cursor: 'pointer',
    },
    affixStyle: {
        position: 'sticky',
        top: '0px',
        zIndex: '2',
    },
});

const newUnfilledFormData = () => {
    return {name: '', prettyName: '', type: '', sql: '', id: uuidv4() };
};

export const getOperations = (type:string) => {
    if(type === 'number'){
        return [
            { value: 'avg', prettyName: 'Average', type: 'number'},
            { value: 'count', prettyName: 'Count', type: 'number'},
            { value: 'count_distinct', prettyName: 'Count Distinct', type: 'number'},
            { value: 'max', prettyName: 'Maximum', type: 'number'},
            { value: 'min', prettyName: 'Minimum', type: 'number'},
            { value: 'sum', prettyName: 'Sum', type: 'number'},
            { value: 'any', prettyName: 'Any', type: 'number'}];
    }
    if(type === 'boolean'){
        return [
            { value: 'count', prettyName: 'Count', type: 'number'},
            { value: 'count_true', prettyName: 'Count True', type: 'number'},
            { value: 'count_not_true', prettyName: 'Count Not True', type: 'number'},
            { value: 'any', prettyName: 'Any', type: 'boolean'}];
    }
    if(type === 'text'){
        return [
            { value: 'count', prettyName: 'Count', type: 'number'},
            { value: 'count_distinct', prettyName: 'Count Distinct', type: 'number'},
            { value: 'any', prettyName: 'Any', type: 'text'}];
    }
    if(type === 'timestampz'){
        return [
            { value: 'count', prettyName: 'Count', type: 'number'},
            { value: 'count_distinct', prettyName: 'Count Distinct', type: 'number'},
            { value: 'max', prettyName: 'Maximum', type: 'timestampz'},
            { value: 'min', prettyName: 'Minimum', type: 'timestampz'},
            { value: 'any', prettyName: 'Any', type: 'timestampz'}];
    }
    if(type === 'date'){
        return [
            { value: 'count', prettyName: 'Count', type: 'number'},
            { value: 'count_distinct', prettyName: 'Count Distinct', type: 'number'},
            { value: 'max', prettyName: 'Maximum', type: 'date'},
            { value: 'min', prettyName: 'Minimum', type: 'date'},
            { value: 'any', prettyName: 'Any', type: 'date'}];
    }
    return [];
};

export const buildObjectFromCustomColumnObj = (obj:any, customColumnType) => {
    if(customColumnType === 'global'){
        return {
            ...obj,
            value: obj.name,
            groupBy: {
                allowed: true,
                buckets: (obj.type === 'timestampz' || obj.type === 'date') ? [
                    { prettyName: 'Hour', value: 'hour'},
                    { prettyName: 'Day', value: 'day'},
                    { prettyName: 'Week', value: 'week'},
                    { prettyName: 'Month', value: 'month'},
                    { prettyName: 'Quarter', value: 'quarter'},
                    { prettyName: 'Year', value: 'year'}]:[],
            },
            operations: getOperations(obj.type),
            filterMetaData: {
                required: false,
            },
            customColumnType,
        };
    }
    if(customColumnType === 'local'){
        return {
            ...obj,
            value: obj.name,
            groupBy: {
                allowed: false,
            },
            operations: null,
            filterMetaData: {
                required: false,
            },
            customColumnType,
        };
    }
    return obj;
};

const checkIfAnyValueIsEmpty = (obj) => {
    const keys = Object.keys(obj);
    return keys.some((key) => {
        return obj[key] === '';
    });
};

const CustomColumnDrawer = (props) => {
    const { t } = useTranslation();
    const { columns=[], onChange, onRemove, visible, customColumnType, setCustomColumnType, setReset,
        hideAddMoreOption } = props;
    const state = useSelector((state:any) => state);
    const selectedReport = state.reportMetaData.selectedReport;
    const customMetrices = state.customMetrices[customColumnType];
    const dispatch = useDispatch();
    const classes = useStyle();
    const metricData = useSelector((state:any) => state.metrics) || {};
    const summarizationData = useSelector((state: any) => state.summarization) || {};
    const downloadRowLimitConfig = useSelector((state:any) => state?.masterData?.organisationConfig?.downloadRowLimitConfig);
    const enableRedshiftDump = useSelector((state:any) => state?.masterData?.organisationConfig?.enableRedshiftDump);
    const selectedReportType = useSelector((state: any) => state.reportMetaData.selectedReport);
    const selectedDbType = useSelector((state: any) => state.reportConfig?.reports[selectedReportType]?.dbType);
    const maxElasticSearchRowsToDownload = useSelector((state: any) => state.masterData.organisationConfig?.maxElasticSearchRowsToDownload);

    const addMoreColumn = () => {
        const newUnfilledForm = newUnfilledFormData();
        onChange(newUnfilledForm.id, newUnfilledForm);
    };
    const [toRemove,setToRemove] = useState<boolean>(false);
    useEffect(() => {
        if(customColumnType !== '' && lodashIsEmpty(customMetrices) === true){
            addMoreColumn();
        }
    },[customColumnType]);

    const addCustomFieldsToMasterData = () => {
        try{
            const keys = Object.keys(customMetrices);
            if(keys.some((key) => checkIfAnyValueIsEmpty(customMetrices[key]))){
                throw new Error(t('Any Field should not be empty'));
            }
            const buildObjArray = keys.map((key) => {
                return buildObjectFromCustomColumnObj(customMetrices[key],customColumnType);
            });

            if(customColumnType === 'global'){
                let metrices = state.reportConfig.reports[selectedReport].metrics;
                metrices = metrices.filter(item => !item.customColumnType || !(item.customColumnType === customColumnType));
                metrices = [...metrices, ...buildObjArray];
                const payload = {
                    ...state.masterData,
                    reports:{
                        ...state.reportConfig.reports,
                        [selectedReport]: {
                            ...state.reportConfig.reports[selectedReport],
                            metrics: metrices,
                        },
                    },
                };
                dispatch({type: SET_REPORT_MASTER_DATA, payload});
            } else if (customColumnType === 'local') {
                const previousColumnsLength = Object.keys({ ...metricData, ...summarizationData }).length;
                const metric = lodashValues(metricData);
                const toRemoveMetric = metric.filter(item => item.customColumnType === 'local');

                toRemoveMetric.forEach(item => {
                    delete metricData[item.value];
                });
                buildObjArray.forEach(item => {
                    metricData[item.value] = item;
                });

                const currentColumnsLength = Object.keys({ ...metricData, ...summarizationData }).length;
                const downloadRowLimitWarningMessage: string | undefined = getDownloadRowLimitWarningMessage({
                    downloadRowLimitConfig,
                    previousColumnsLength,
                    currentColumnsLength,
                    enableRedshiftDump,
                    selectedDbType,
                    maxElasticSearchRowsToDownload,
                });

                if (downloadRowLimitWarningMessage) {
                    message.warning(downloadRowLimitWarningMessage);
                }

                dispatch({
                    type: METRICS_CHANGED,
                    payload: metricData,
                });
            }
        }
        catch(err){
            message.error((err as Error).message);
        }
    };
    const addCustomFields = () => {
        addCustomFieldsToMasterData();
        setCustomColumnType('');
    };
    useEffect(() => {
        if(toRemove){
            addCustomFieldsToMasterData();
            setToRemove(false);
        }
    },[toRemove]);
    return <Drawer
        placement="right"
        width={422}
        bodyStyle={{padding: '0px'}}
        closable={false}
        visible={visible}>
            <Affix offsetTop={1} className={classes.affixStyle}>
                <DrawerTitleBar
                    onClickButton={addCustomFields}
                    onClose={() => setCustomColumnType('')}
                    title={t('Custom Metric')}
                    buttonText={t('Add')}/>
            </Affix>
            <div className={classes.body}>
            <div className={classes.descriptionText}>{t('Add multiple customized fields')}</div>
            {columns.map((data, j) => {
                return <CustomColumnsDataCard
                    onRemove={() => {onRemove(data); setToRemove(true);}}
                    onChange={(data) => {
                        onChange(data.id, data);
                    }}
                    data={data}/>;
            })}
            <Divider/>
            {!hideAddMoreOption &&
                <Button onClick={addMoreColumn} style={{padding:'0'}} type="link">+ {t('Add More')}</Button>
            }
            </div>
    </Drawer>;
};

export default CustomColumnDrawer;
