import { DesktopOutlined, LoadingOutlined } from '@ant-design/icons';
import { ConfigProvider, Select } from 'antd';
import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchMetrics from '../hooks/useSearchMetrics';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const InputFilter = (props) => {
    const { t } = useTranslation();
    const customizeRenderLoading = () => (
        <div style={{ textAlign: 'center' }}>
          <LoadingOutlined />
          <p>{t('Fetching List')}</p>
        </div>
    );
    
    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center' }}>
          <DesktopOutlined />
          <p>{t('No Data')}</p>
        </div>
    );
    const {
        value,
        onChange,
        metricsName,
        multipleSelect,
        selectedFilterReportType,
        prettyName,
        isEditing,
        isTabletOrMobile = false,
        defaultOpen = false,
        autoFocus = false,
      } = props;
    const globalReportName = useSelector(((state:any) => state?.reportMetaData?.selectedReport)) || '';
    const reportName = selectedFilterReportType ? selectedFilterReportType : globalReportName;
    const [searchQuery,changeSearchQuery] = useState<string>('');
    const {getSearchFilterSuggestion, optionsData, optionsLoading} = useSearchMetrics(reportName,metricsName,searchQuery);
    const suggestedFilterStrings = useSelector((state:any) => state.reportMetaData.suggestedFilterStrings) || [];
    const [optionsArrayData,setOptionsAraayData] =  useState<any[]>([]);
    useEffect(() => {
        getFilterOptions();
    }, []);

    useEffect(() => {
        getSuggestedFilterOptions();
    }, [suggestedFilterStrings]);

    const searchHandler = async (value:string) => {
        optionsArrayData?.length>0 ? null: changeSearchQuery(value);
    };

    const getSuggestedFilterOptions = () => {
        let suggestedFiltersPresent = false;
        suggestedFilterStrings?.forEach((element) => {
            if(element.key === prettyName){
                setOptionsAraayData(element.value.map(x => {
                    return {
                        label: t(x),
                        value: x,
                    };
                }));
                suggestedFiltersPresent = true;
            }
        });
    };

    const getFilterOptions = () => {
        getSuggestedFilterOptions();
        getMetaDataOptions();
    };

    const getMetaDataOptions = () => {
        if(!optionsData.length) {
            getSearchFilterSuggestion();
        }
    };

    const getSelectOptions = () => {
        // while editing, show only suggested filter options while editing if present
        // if no suggested filter is present, show metadata options
        // while creation, show both suggested filter options and metadata options
        const combinedOptions = [...optionsArrayData, ...optionsData];
        const uniqueOptions = uniqBy(combinedOptions, 'value');

        return !isEditing
              ? uniqueOptions
              : optionsArrayData.length > 0
              ? optionsArrayData
              : optionsData;
    };

    return (<ConfigProvider renderEmpty={optionsLoading ? customizeRenderLoading : customizeRenderEmpty}>
        <Select
            getPopupContainer={isTabletOrMobile ? undefined : (trigger) => trigger.parentNode}
            mode= {multipleSelect?'tags': undefined}
            showSearch
            showArrow={true}
            loading={optionsLoading}
            style={{width:'100%'}}
            onSearch={debounce(searchHandler,500)}
            value={value?.value}
            maxTagCount="responsive"
            tokenSeparators={[',', '\n', '\r', '\t']}
            onChange={(e) => onChange(e)}
            options={getSelectOptions()}
            dropdownStyle={{height:isTabletOrMobile? '11rem' : undefined}}
            autoFocus={autoFocus}
            defaultOpen={defaultOpen}
        />
    </ConfigProvider>);
};

export default InputFilter;
