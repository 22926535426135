import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';

const en = require('./translations/en.json');
const pt = require('./translations/pt.json');

const resources = {
  en,
  pt,
};

// Create a custom detector
const customLanguageDetector = {
  name: 'customLocalStorageDetector',
  lookup() {
    const sharedCredential = localStorage.getItem('sharedCredential');
    if (sharedCredential) {
      try {
        const parsedData = JSON.parse(sharedCredential);
        return parsedData.metadata?.lng || null; // Return the language if it exists
      } catch (error) {
        console.error('Error parsing sharedCredential:', error);
        return null; // Default to null if parsing fails
      }
    }
    return null; // Default to null if the key doesn't exist
  },
  cacheUserLanguage() {
    // Not needed in this case because we're not caching the language
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customLanguageDetector);

i18n
  .use(languageDetector) // Use the language detector with the custom detector added
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    ns: ['translation'],
    defaultNS: 'translation',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['customLocalStorageDetector', 'navigator'], // Use the custom detector first
      caches: [], // Avoid caching detected language to localStorage or sessionStorage
    },
    react: {
      useSuspense: false, // Disable Suspense
    },
  });

export default i18n;
