import React from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { entityRouteMap } from 'src/constants';
import { useTranslation } from 'react-i18next';

const useStyle = createUseStyles({
  createAlertandScheduler: {
    height: '14px',
    color: '#1890FF',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '14px',
  },
  schedulerAndAlertIcon: {
    fontSize: '14px',
    marginRight: '8px',
  },
  manageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E9FBFF',
    },
  },
  createContainer: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E9FBFF',
    },
  },
  notAllowed: {
    cursor: 'no-drop',
    color: 'darkgrey',
  },
  numberOfSchedules: {
    marginRight: '11px',
    paddingLeft: '3px',
    fontSize: '14px',
  },
});

const SchedulerAction = (props) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();
  const {
    record,
    scheduler,
    entityType,
    onOpenScheduler,
    fromDashboardListingPage = true,
  } = props;

  const onClickCreateScheduler = () => {
    if (fromDashboardListingPage) {
      scheduler.openDrawer({
        entityId: record._id,
        entityType,
      });
    } else {
      onOpenScheduler({ entityId: record._id, entityType });
    }
  };

  return (
    <>
      {record.numSchedules === 0 ? (
        <div
          className={classes.createContainer}
          onClick={() => onClickCreateScheduler()}
        >
          <CalendarOutlined className={classes.schedulerAndAlertIcon} />
          {t('Create Scheduler')}
        </div>
      ) : (
        <div
          className={classes.manageContainer}
          onClick={() =>
            history.push(
              `/${entityRouteMap[entityType]}/scheduler/${record._id}`,
            )
          }
        >
          <div className={classes.numberOfSchedules}>{record.numSchedules}</div>
          {t('Manage Scheduler')}
        </div>
      )}
    </>
  );
};
export default SchedulerAction;
