import { Input, Tag, Tooltip, Switch, Button, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useToggle } from './hooks/toggle';
import { CloseOutlined, LinkOutlined, FilterOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useHover } from '@react-aria/interactions';
import CustomDivider from './CustomDivider';
import { useLocation } from 'react-router-dom';
import LinkReportModal from '../pages/chartBuilder/linkReportModal';
import { ReactComponent as DragSVG } from 'src/assets/drag.svg';
import { ReactComponent as DataDumpSVG } from 'src/assets/DataDump.svg';
import { useTranslation } from 'react-i18next';
const useStyles = createUseStyles({
  rowContainer: {
    height: '3.25rem',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftPane: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: '0',
  },
  title: {
    display: 'flex',
    height: '28px',
    color: '#262626',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '14px',
    alignItems: 'center',
    cursor: 'text',
  },
  img: {
    margin: '0 6px 0 6px',
    width: '16px',
    height: '16px',
  },
  editableTitle: {
    border: '1px solid #1890FF',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    height: '28px',
    fontSize: '12px',
  },
  leftEndText: {
    height: '12px',
    color: '#1890FF',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '12px',
  },
  rightPane: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
  },
  rightPaneBox: {
    display: 'flex',
    gap: '0.5em',
  },
  iconContainer: {
    height: '20px',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '6px',
  },
  topContainer: {
    width: '100%',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
  },
  dropDownArrow: {
    marginLeft: '4px',
    fontSize: '0.7rem',
  },
  filterIcon: {
    marginLeft: '8px',
  },
  basePane: {
    marginTop: '7px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterPane: {
    alignItems: 'center',
  },
  closeIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '10px',
    height: '10px',
    marginLeft: 'auto',
  },
  drilldown: {
    display: 'flex',
    alignItems: 'center',
  },
  switch: {
    margin: '0px 6px 0px 0px',
  },
  dummyDataRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1em',
  },
  dummyDataIcon: {
    height: '20px',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topRightFilter: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '15px',
    marginLeft: 'auto',
    gap: '10px',
    position: 'relative',
  },
});

const RowContainer = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    title,
    isTitleEditable = false,
    onTitleChange,
    isTitleRequired = false,
    isFilterListMutable = true,
    prettyName,
    description = prettyName ?? title,
    showLinkReport = false,
    customDivider = true,
    children,
    leftPaneProps,
    LeftPaneComponent,
    showLeftPane = false,
    onRemove,
    aggregate,
    isRowDisabled = false,
    LeftPaneFilterComponent,
    showLeftPaneFilterComponent = false,
    initialSuggestedFilters = [],
    onAddLinkedReport,
    rowType,
    hideRemove,
    linkedReport,
    draggableIcon = false,
    showDrilldown = false,
    handleDrilldown,
    drilldownData,
    isColumn,
    columnDataType,
    columnOperation,
    columnName,
    setRenderDataDumpDrawer,
    setColumnData,
    showTopRightFilter = true,
    showPopOverComponent = false,
    PopOverButton,
    PopOverContent,
    showSuggestionFilter=true,
    setDummyDataVisibility=true,
  } = props;
  const [suggestionFilterVisible, setsuggestionFilterVisible] = useState(false);
  const [linkReportVisible, setLinkReportVisible] = useState(false);
  const [popOverVisible, setPopOverVisible] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setPopOverVisible(newOpen);
  };
  const { hoverProps, isHovered } = useHover({
    onHoverStart: (e) => {},
    onHoverEnd: (e) => {},
  });
  const location = useLocation();
  const isDashboardUrl = location.pathname.includes('dashboard');
  const showDataDumpDrawer = () => {
    setRenderDataDumpDrawer(true);
    setColumnData({
      columnDataType,
      columnOperation,
      columnName,
    });
  };

  const showDummyDataSetterIcon = () => {
    return (
      !isColumn ||
      (columnDataType === 'boolean' &&
      (!columnOperation || columnOperation === 'any')) || (!setDummyDataVisibility)
    );
  };

  return (
    <div
      className={classes.basePane}
      {...hoverProps}
      style={{ opacity: isRowDisabled ? 0.5 : 1 }}
    >
      <div className={classes.topContainer}>
        <div className={classes.rowContainer}>
          <div className={classes.leftPane}>
            <Tooltip placement="right" title={t(description)}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {draggableIcon && (
                  <div
                    style={{
                      alignItems: 'center',
                      padding: '0.2rem 0.6rem 0.4rem 0.4rem',
                    }}
                  >
                    <DragSVG />
                  </div>
                )}
                <Title
                  required={isTitleRequired}
                  isTitleEditable={isTitleEditable}
                  value={t(title)}
                  classes={classes}
                  aggregate={aggregate}
                  onChange={onTitleChange}
                />
              </div>
            </Tooltip>
          </div>
          {showTopRightFilter && (
            <div className={classes.rightPaneBox}>
              <div className={classes.topRightFilter}>
                {showLeftPane && (
                  <LeftPaneComponent
                    title={t(title)}
                    {...leftPaneProps}
                    classes={classes}
                  />
                )}
                {showPopOverComponent && (
                  <Popover
                    content={PopOverContent}
                    title={t('Filter Inputs')}
                    trigger="click"
                    visible={popOverVisible}
                    onVisibleChange={handleOpenChange}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                  >
                    <PopOverButton />
                  </Popover>
                )}
                {showLeftPaneFilterComponent && (
                  <LeftPaneFilterComponent
                    suggestionFilterVisible={suggestionFilterVisible}
                    setsuggestionFilterVisible={setsuggestionFilterVisible}
                    prettyNameOfMetric={title}
                    initialSuggestedFilters={initialSuggestedFilters}
                    {...leftPaneProps}
                    classes={classes}
                  />
                )}
                {!isDashboardUrl &&
                  rowType === 'text' &&
                  showSuggestionFilter && (
                    <>
                      <a onClick={(e) => e.preventDefault()}> </a>
                      <FilterOutlined
                        onClick={() => {
                          setsuggestionFilterVisible(true);
                        }}
                        className={classes.dropDownArrow}
                      />
                    </>
                  )}
                {showLinkReport && (
                  <div className={classes.closeIcon}>
                    <LinkOutlined onClick={() => setLinkReportVisible(true)} />
                  </div>
                )}
              </div>
              {showDummyDataSetterIcon() ? null : (
                <div className={classes.dummyDataRow}>
                  <Button
                    onClick={showDataDumpDrawer}
                    icon={<DataDumpSVG />}
                    className={classes.dummyDataIcon}
                  />
                </div>
              )}
              {!hideRemove && (
                <div className={classes.closeIcon}>
                  <CloseOutlined
                    onClick={onRemove}
                    style={{
                      color: '#000',
                      display:
                        isHovered && isFilterListMutable ? 'block' : 'none',
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {children}
        {showLinkReport && (
          <LinkReportModal
            visible={linkReportVisible}
            setVisible={setLinkReportVisible}
            onAddLinkedReport={onAddLinkedReport}
            linkedReport={linkedReport}
          />
        )}
        {showDrilldown ? (
          <div className={classes.drilldown}>
            <Switch
              size={'small'}
              className={classes.switch}
              onChange={handleDrilldown}
              defaultChecked={drilldownData?.toApply || false}
            />
            <span>{t('Drill Down')}</span>
          </div>
        ) : null}
        {customDivider ? <CustomDivider /> : null}
      </div>
    </div>
  );
};

const Title = (props) => {
  const { t } = useTranslation();
  const { onChange, value, classes, isTitleEditable, required, aggregate } =
    props;
  const [state, toggle] = useToggle(false);
  const [textValue, setText] = useState(value);
  useEffect(() => setText(value), [value]);
  const onInputBlur = (event) => {
    setText(event.target.value);
    onChange(event.target.value);
    toggle();
  };

  if (state && isTitleEditable) {
    return (
      <Input
        autoFocus={true}
        className={classes.editableTitle}
        defaultValue={textValue}
        onBlur={onInputBlur}
        onPressEnter={onInputBlur}
      />
    );
  }
  return (
    <span
      onClick={toggle}
      className={classNames({ [classes.title]: true, required })}
    >
      {aggregate ? (
        <>
          <Tag color="red">{t(aggregate)} {t('of')}</Tag>
        </>
      ) : null}
      {t(textValue)}
    </span>
  );
};

export default RowContainer;
