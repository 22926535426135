import React, { useEffect } from 'react';
import { Select, Avatar, Popover, Tooltip } from 'antd';
import { createUseStyles } from 'react-jss';
import useDebounce from './hooks/useDebounce';
import { POST } from 'src/api/apiHandler';
import config from 'src/config';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
const { BACKEND_URL } = config;

const useStyle = createUseStyles({
  basePane: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px',
    marginBottom: '20px',
  },
  prettyName: {
    lineHeight: '14px',
    letterSpacing: '0px',
    color: '#303030',
    height: '14px',
    marginBottom: '0.5rem',
    fontSize: '12px',
  },
});

const SelectFilter = (props) => {
  const { t } = useTranslation();
  const {
    onChange,
    prettyName,
    value,
    type = 'singleSelect',
    data,
    placeHolder = 'Select',
    size = 'small',
    showSearch = false,
    style,
    apiObject,
    isOptional,
    ...rest
  } = props;
  const isMultiSelect = type !== 'singleSelect' ? true : false;
  const onChangeSelect = (value) => onChange(value);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [options, setOptions] = React.useState<any[]>([]);

  useEffect(() => {
    if (!apiObject?.api) {
      setOptions(data?.options || []);
    }
  }, [data?.options]);

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  useEffect(() => {
    fetchOptions(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  const fetchOptions = async (searchQuery) => {
    if (!apiObject?.api) return;
    const baseURL = BACKEND_URL;
    const apiPayload = apiObject.apiPayload || {};
    apiPayload.searchQuery = searchQuery;
    const url = `${baseURL}api/report/${apiObject.api}`;
    const response = await POST(url, apiPayload);
    const options =
      response?.data?.reports?.map((item) => ({
        prettyName: item.name,
        value: item._id,
      })) || [];
    setOptions(options);
  };

  useEffect(() => {
    if (!value && options.length > 0) {
      if (!isMultiSelect && !isOptional) {
        onChange(options[0].value);
      }
    }
  }, [value, options]);

  const getMode = () => {
    if (isMultiSelect) {
      if (apiObject?.api) {
        return 'multiple';
      }
      return 'tags';
    }
    return undefined;
  };

  return (
    <>
      <Select
        getPopupContainer={(trigger) => trigger.parentNode}
        showSearch={apiObject?.api ? true : showSearch}
        style={style}
        size={size}
        placeholder={placeHolder}
        mode={getMode()}
        maxTagCount={isMultiSelect ? 1 : undefined}
        onChange={onChangeSelect}
        value={value}
        allowClear={isOptional}
        onSearch={(searchQuery) => {
          if (apiObject?.api) {
            setSearchQuery(searchQuery);
          }
        }}
        onFocus={() => {
          if (apiObject?.api) {
            setSearchQuery('');
          }
        }}
        filterOption={
          apiObject?.api
            ? false
            : (input, option) =>
                JSON.stringify(option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
        }
        maxTagPlaceholder={
          isMultiSelect ? (arr) => <>+{arr.length} {t('More')}</> : null
        }
        {...rest}
      >
        {options.map((singleOption: any, i) => (
          <Option
            label={t(singleOption.prettyName)}
            value={singleOption.value}
            key={i}
          >
            <Tooltip
              placement="right"
              title={t(singleOption.description) || t(singleOption.prettyName)}
            >
              {t(singleOption.prettyName)}
            </Tooltip>
          </Option>
        ))}
      </Select>
    </>
  );
};

const LabelHandlerComponent = (props) => {
  const classes = useStyle();
  const { prettyName, noLabel = false, mandatory, ...rest } = props;
  const selectProps = { ...props, ...rest };
  return noLabel ? (
    <SelectFilter {...selectProps} />
  ) : (
    <div className={classes.basePane}>
      <span className={classes.prettyName}>
        {prettyName} {mandatory && <span style={{ color: 'red' }}>*</span>}
      </span>
      <SelectFilter {...selectProps} />
    </div>
  );
};

export default LabelHandlerComponent;
