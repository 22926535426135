import React, { memo, useEffect, useState, useRef } from 'react';
import { Scene, Zoom, Fullscreen } from '@antv/l7';
import { Mapbox } from '@antv/l7-maps';
import { createUseStyles } from 'react-jss';
import tileStyle from './MapTileStyle';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { mapCountryCoordinates } from 'src/constants';
import {
  createMapData,
  extractData,
  fetchReportData,
  getGlobalFilters,
  mapMarkerConfig,
  setupPolygonLayers,
  setupPopups,
  setupScene,
  getBoundsFromPoints,
} from './utils';

const useStyles = createUseStyles({
  map: {
    height: '100%',
    width: '100%',
    position: 'relative',
    '& .l7-control-container .l7-column.l7-left': {
      display: 'none',
    },
  },
});

const Map = (props) => {
  const {
    reportId,
    chartData: mainReportData,
    visualizeMetaData,
    chartsFromDashboard,
  } = props;
  const classes = useStyles();
  const id = reportId || 'map-container';
  const location = useLocation();
  const isVisualizeDashboard = location.pathname.includes('visualize');

  // refs required to cleanup the map scene
  const pointLayerRef: any = useRef(null);
  const polygonLayerRef: any = useRef(null);
  const sceneRef: any = useRef(null);

  const orgConfigData = useSelector(
    (state: any) => state.masterData.organisationConfig,
  );
  const chartsMetaData = useSelector((state: any) => state.chartsMetaData);
  const dashboardGroupMetaData = useSelector(
    (state: any) => state.dashboardGroupMetaData,
  );
  const dashboards = dashboardGroupMetaData?.metadata?.dashboards || [];
  const dashboardFilter = useSelector((state: any) => state.dashboardFilter);
  const supportedFilters = useSelector(
    (state: any) => state.masterData?.supportedFilters,
  );
  const isAutoRefresh = dashboardGroupMetaData?.isAutoRefresh;
  const lastUpdatedTime = dashboardGroupMetaData?.lastUpdatedTime;
  const globalFilters = getGlobalFilters(
    dashboardGroupMetaData,
    dashboardFilter,
    supportedFilters,
  );
  const enableDummyDataSwitch = useSelector(
    (state: any) => state.reportMetaData.dummyDataSwitch,
  );

  const [linkedReports, setLinkedReports] = useState<string[]>([]);
  const [linkedReportsData, setLinkedReportsData] = useState<any>({});
  const [mainReportPolygonProperties, setMainReportPolygonProperties] =
    useState<any>({
      radius: 0,
      startColor: '',
      endColor: '',
    });

  useEffect(() => {
    if (isVisualizeDashboard) {
      setLinkedReports(
        chartsMetaData?.chartOptions?.geolocation?.overlayReports?.data || [],
      );
      setMainReportPolygonProperties({
        radius: chartsMetaData?.chartOptions?.geolocation?.radius?.data || 0,
        startColor:
          chartsMetaData?.chartOptions?.geolocation?.startColor?.data || '',
        endColor:
          chartsMetaData?.chartOptions?.geolocation?.endColor?.data || '',
      });
    } else {
      const reports =
        dashboards.flatMap(
          (dashboard) => dashboard?.dashboardVisualisations?.reports,
        ) || [];
      const overlayReportsArray: string[] = [];

      reports.forEach((report) => {
        if (report.reportId === id) {
          const overlayReportsOption = report?.chartOptions?.find(
            (option) => option.option === 'overlayReports',
          );
          if (overlayReportsOption?.data) {
            overlayReportsArray.push(
              ...(JSON.parse(overlayReportsOption.data)?.value || []),
            );
          }

          if (!mainReportPolygonProperties?.radius) {
            const polygonProperties: any = {};
            report?.chartOptions?.forEach((option) => {
              if (option.option === 'radius') {
                polygonProperties.radius = JSON.parse(option.data).value || 0;
              } else if (option.option === 'startColor') {
                polygonProperties.startColor =
                  JSON.parse(option.data).value || '';
              } else if (option.option === 'endColor') {
                polygonProperties.endColor =
                  JSON.parse(option.data).value || '';
              }
            });
            setMainReportPolygonProperties(polygonProperties);
          }
        }
      });

      setLinkedReports(overlayReportsArray || []);
    }
  }, []);

  const fetchAllReportData = async () => {
    const newLinkedReportsData: any = {};

    for (const reportId of linkedReports) {
      const reportData = await fetchReportData(
        reportId,
        globalFilters,
        enableDummyDataSwitch,
      );
      if (reportData) {
        newLinkedReportsData[reportId] = reportData;
      }
    }

    setLinkedReportsData(newLinkedReportsData);
  };

  useEffect(() => {
    if (linkedReports.length) {
      fetchAllReportData();
    }
  }, [linkedReports]);

  useEffect(() => {
    // Fetch linked reports data on auto refresh
    if (isAutoRefresh && linkedReports.length) {
      fetchAllReportData();
    }
  }, [lastUpdatedTime, isAutoRefresh]);

  const getMapCenter = () => {
    const country = orgConfigData?.defaultCountry;
    return mapCountryCoordinates[country] || mapCountryCoordinates.default;
  };

  const initialiseMap = () => {
    const mapData = createMapData(
      mainReportData,
      linkedReportsData,
      mainReportPolygonProperties,
    );
    const mainReportWithPolygonProperties = mainReportData.map((data) => ({
      ...data,
      ...(mainReportPolygonProperties?.radius > 0 && {
        ...mainReportPolygonProperties,
      }),
    }));
    const scene = new Scene({
      id,
      map: new Mapbox({
        style: tileStyle,
        center: getMapCenter(),
        zoom: 8,
      }),
    });

    scene.on('loaded', () => {
      const marginBottom = chartsFromDashboard ? '8px' : '55px';
      scene.addControl(new Zoom({ style: `margin-bottom: ${marginBottom}` }));
      scene.addControl(new Fullscreen());

      // Load custom images for markers
      Object.keys(mapMarkerConfig).forEach((key) => {
        scene.addImage(key, mapMarkerConfig[key].image);
      });

      const bounds: any = getBoundsFromPoints(mapData);
      if (mapData.length) {
        scene.fitBounds(bounds, { animate: false });
      }

      // Initialize the map scene with the data
      setupScene(scene, mapData, pointLayerRef);
      setupPolygonLayers(
        scene,
        mainReportWithPolygonProperties,
        linkedReportsData,
        polygonLayerRef,
      );
      const allData = extractData(visualizeMetaData, linkedReportsData);
      setupPopups(scene, pointLayerRef.current, allData);
    });

    sceneRef.current = scene;
  };

  useEffect(() => {
    const scene = sceneRef.current;
    return () => {
      if (scene) {
        scene.destroy();
      }
      pointLayerRef.current = null;
      polygonLayerRef.current = null;
      sceneRef.current = null;
    };
  }, [id, mainReportData]);

  useEffect(() => {
    const scene = sceneRef.current;
    if (!scene) {
      initialiseMap();
    } else {
      const mapData = createMapData(
        mainReportData,
        linkedReportsData,
        mainReportPolygonProperties,
      );
      const mainReportWithPolygonProperties = mainReportData.map((data) => ({
        ...data,
        ...(mainReportPolygonProperties?.radius > 0 && {
          ...mainReportPolygonProperties,
        }),
      }));
      // Setup the map scene with the new data
      setupScene(scene, mapData, pointLayerRef);
      setupPolygonLayers(
        scene,
        mainReportWithPolygonProperties,
        linkedReportsData,
        polygonLayerRef,
      );
      const allData = extractData(visualizeMetaData, linkedReportsData);
      setupPopups(scene, pointLayerRef.current, allData);
    }
  }, [mainReportData, linkedReportsData, mainReportPolygonProperties]);

  return <div id={id} className={classes.map}></div>;
};

export default memo(Map);
